import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.less']
})
export class MultiSelectComponent<T> implements AfterViewInit {
  @ViewChild('container') container: ElementRef<HTMLDivElement>;

  @Input() items: T[];
  @Input() converter: (v: T) => string;

  @Input() threshold: number = 10;

  selected: T[] = [];
  
  filter: string;
  active = false;

  @Input() selection: T[];
  @Output() selectionChange: EventEmitter<T[]> = new EventEmitter<T[]>();
  
  get filtered_items(): T[] {
    let items = this.items ?? [];
    // if (this.selected?.length) {
    //   items = items.filter(i => !this.selected.includes(i));
    // }
    if (this.filter) {
      const filter = this.filter.toLowerCase();
      items = items.filter(i => this.converter(i).toLowerCase().includes(filter));
    }
    return items;
  }
  
  constructor() { }

  ngAfterViewInit() {
    this.selectionChange.emit(this.selected);
  }

  show() {
    const events = ['mousedown', 'focus'];
    const listener = (event: Event) => {
      const container = this.container.nativeElement;
      if (event.target !==  container && !container.contains(<any>event.target)) {
        this.active = false;
        for (let event of events)
          document.removeEventListener(event, listener);
      }
    };
    for (let event of events)
      document.addEventListener(event, listener);

    this.active = true;
  }

  add(item: T) {
    this.selected.push(item);
    this.selectionChange.emit(this.selected);
  }

  remove(item: T) {
    this.selected.splice(this.selected.indexOf(item), 1);
    this.selectionChange.emit(this.selected);
  }

  remove_all() {
    this.selected = [];
    this.selectionChange.emit(this.selected);
  }

  select_all() {
    this.selected = this.items.map(i => i);
    this.selectionChange.emit(this.selected);
  }
}
