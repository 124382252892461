import { Component, Input } from "@angular/core";

@Component({
   selector: 'busy',
   template: '<div class="loader" style="border-top: solid {{color}}; width: {{size}}px; height: {{size}}px; border-width: {{size * thickness}}px"></div>',
   styleUrls: ['./busy.less']
})
export class BusyComponent {
   @Input() color = 'white';
   @Input() size = 14;
   @Input() thickness = .25;
}