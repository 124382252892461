import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommerceOperation } from 'src/app/shared/models/models';
import { DataService } from 'src/app/shared/services/data.service';
import { State } from 'src/app/shared/state.shared';
import { error_toast } from 'src/app/utilities/toaster/toaster.utilities';

@Component({
  selector: 'app-admin',
  templateUrl: './conciliation.component.html',
  styleUrls: []
})
export class ConciliationComponent {
  commerces: CommerceOperation[];
  filter: string;
  intermediarios: string[] = ["Todos"];
  selected_intermediary: string = "Todos";

  show_0_balance: boolean = false;

  get filtered_commerces(): CommerceOperation[] {
    var temp_commerces = this.commerces;
    if (!this.show_0_balance)
      temp_commerces = this.commerces?.filter(c => c.balance != 0);
    
    if (this.selected_intermediary && this.selected_intermediary !== "Todos") 
      temp_commerces = temp_commerces.filter(c => c.name_intermediary.description === this.selected_intermediary);
      
    return temp_commerces;  
  }

  constructor(api: DataService, private router: Router) {
    api.commerces_operation(response => {
      if (response.succeeded) {
        this.commerces = response.data;
        this.commerces.forEach(c => !c.name_intermediary ?  c.name_intermediary = {id: -1, description: "N/A"} : true);
        this.intermediarios = ["Todos", ...Array.from(new Set(this.commerces.map(c =>c.name_intermediary ? c.name_intermediary.description : "N/A")))];
        this.commerces.sort((a, b) => b.balance - a.balance);
      } else error_toast(response.error.message);
    });
  }

  go(commerce_id: number) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['commerces', 'conciliation', commerce_id]));
    window.open(url, '_blank');
  }

  permiss() {
   return State.getUserPermiss('Gestionar comercios')
  }
}
