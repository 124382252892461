declare global {
   interface Array<T> {
      distinct<K>(key?: (item: T) => K): T[];
      group<K>(key: (item: T) => K): { key: K; items: T[] }[];
   }
}

Array.prototype.distinct ??= function<T, K>(this: T[], key?: (item: T) => K): T[] {
   if (key)
      return this.filter((item, index) => {
         const k = key(item);
         return this.findIndex(i => key(i) == k) === index;
      });
   return this.filter((item, index) => this.indexOf(item) === index);
}

Array.prototype.group ??= function<T, K>(this: T[], key: (item: T) => K): { key: K; items: T[] }[] {
   const groups = new Map<K, T[]>();
   for (let item of this) {
      const _key = key(item);
      if (groups.has(_key))
         groups.get(_key).push(item);
      else
         groups.set(_key, [item]);
   }
   return Array.from(groups.entries()).map(([key, items]) => ({ key, items }));
}

export {};