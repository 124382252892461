<h2 class="mb-6">Gestión de Comercios</h2>

<div class="card w-full">
   <div class="flex flex-row">
      <table-control #comercios for="puntos de venta" [source]="filtered_commerces" [filter]="filter" class="flex-1"></table-control>
      
      <div class="flex-1" style="display: flex; flex-direction: row; align-items: baseline; margin-bottom: 0.25rem;">
         <span>Intermediario</span>
         <select [(ngModel)]="selected_intermediary" style="padding: 0 .5rem; padding-right: 2rem; margin: 0 .5rem; font-weight: 600; border-radius: 0.25rem;">
            <option *ngFor="let i of intermediarios" [value]="i">{{ i }}</option>
         </select>
      </div>

      <input type=text [(ngModel)]="filter" placeholder="🔍 Filtrar..." class="px-2 border rounded w-80">
   </div>

   <div class="border-t border-b my-3">
      <div (click)="show_0_balance = !show_0_balance" class="cursor-pointer">
         <fa-icon [icon]="show_0_balance? 'toggle-on' : 'toggle-off'" class="align-middle text-lg {{ show_0_balance? 'text-indigo-800' : '' }}"></fa-icon>
         Mostrar comercios con balance $0
      </div>
   </div>

   <table>
      <thead>
         <th>Nombre</th>
         <th>Intermediario</th>
         <th colspan=3>Balance (RD$)</th>
         <th>Días atrasados</th>
         <th *ngIf="permiss()" >Opciones</th>
      </thead> 
      <tbody>
         <tr *ngFor="let commerce of comercios.slice">
            <td>{{ commerce.name }}</td>

            <td>{{ commerce.name_intermediary?.description }}</td>

            <td class="w-0">$</td>
             <td class="text-right w-0 {{ commerce.balance < 0? 'text-green-700 font-medium' : '' }} {{ commerce.days_late > 10? 'text-red-700 font-medium' : '' }}">
               <div class="min-w-max">{{ commerce.balance | currency:' ' }}</div>
            </td>
            <td></td>
            <td class="{{ commerce.days_late > 5 && commerce.days_late <= 10? 'bg-yellow-100' : commerce.days_late > 10? 'bg-red-100 font-medium' : '' }}">
               {{ commerce.days_late }}
            </td>
            <td *ngIf="permiss()">
               <button (click)="go(commerce.id)" class="btn indigo">
                  Gestionar
               </button>
            </td>
         </tr>
      </tbody>
   </table>
   <table-paginator class="mb-2 mt-2" for="puntos de venta"></table-paginator>
</div>
