import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommerceInfo } from 'src/app/shared/models/models';
import { DataService } from 'src/app/shared/services/data.service';
import { error_toast } from 'src/app/utilities/toaster/toaster.utilities';

@Component({
  selector: 'app-points-of-sale-view',
  templateUrl: './points-of-sale-view.component.html',
  styleUrls: []
})
export class PointsOfSaleViewComponent {
  working = true;
  commerces: CommerceInfo[];
  filter: string;
  
  constructor(private router: Router, api: DataService) {
    api.commerces(response => {
      this.working = false;
      if (response.succeeded) 
        this.commerces = response.data;
      else error_toast(response.error.message);
    });
  }
  
  go(commerce_code: string) {
    this.router.navigate(['transactions', 'points-of-sale', commerce_code]); 
  }
}
