import { PointsOfSaleViewComponent } from './points-of-sale-view/points-of-sale-view.component';
import { ContractViewComponent } from './contract-view/contract-view.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PointOfSaleComponent } from './points-of-sale-view/point-of-sale/point-of-sale.component';
import { OpenLotsComponent } from './open-lots/open-lots.component';
import { ClosedLotsComponent } from './closed-lots/closed-lots.component';
import { LotComponent } from './lot/lot.component';
import { TransactionsComponent } from './transactions/transactions.component';

const routes: Routes = [
  { path: 'contracts',              component: ContractViewComponent     },
  { path: 'points-of-sale',         component: PointsOfSaleViewComponent },
  { path: 'points-of-sale/:id',     component: PointOfSaleComponent},
  { path: 'open-lots',              component: OpenLotsComponent         },
  { path: 'closed-lots',            component: ClosedLotsComponent       },
  { path: 'lot/:mode/:id',          component: LotComponent              },
  { path: 'transactions',           component: TransactionsComponent     },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransactionsRoutingModule { }
