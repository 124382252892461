let _dock: HTMLDivElement;
let dock_callbacks: ((dock: HTMLDivElement) => void)[] = []
function dock(callback: (dock: HTMLDivElement) => void) {
   if (_dock)
      callback(_dock);
   dock_callbacks.push(callback);
}
export function set_toast_dock(dock: HTMLDivElement) {
   _dock = dock;
   for (let callback of dock_callbacks)
      callback(dock);
   dock_callbacks = [];
}
const duration_milliseconds: number = 3_000;

function toast(message: string, type: string) {
   dock(dock => {
      const toast = document.createElement('toast');
      toast.classList.add(type);
      toast.innerText = message;
      dock.appendChild(toast);
      dock.classList.add('showing');
      setTimeout(() => {
         toast?.classList.add('dismiss');
         setTimeout(() => {
            dock.removeChild(toast);
            if (!dock.children.length) {
               dock.classList.remove('showing');
            }
         }, 100);
      }, duration_milliseconds)
   })
}

export function success_toast(message: string) {
   toast(message, 'success');
}
export function error_toast(message: string) {
   toast(message, 'error');
}
export function warning_toast(message: string) {
   toast(message, 'warning');
}