export function indeterminate_loader() {
   const loader = <HTMLDivElement>document.getElementById('loader-bar');
   if (loader) {
      loader.className = 'loader-bar indeterminate';
   }
}
export function loader() {
   const loader = <HTMLDivElement>document.getElementById('loader-bar');
   if (loader) {
      loader.className = 'loader-bar determinate';
   }
}
export function end_loader() {
   const loader = <HTMLDivElement>document.getElementById('loader-bar');
   if (loader && !loader.classList.contains('inactive')) {
      loader.className = 'loader-bar determinate done';
      setTimeout(() => {
         if (loader.classList.contains('done'))
            loader.className = 'loader-bar inactive';
      }, 500);
   }
}