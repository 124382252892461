export interface ApiError {
   code: string;
   message: string;
}

export interface ApiResponse<T> {
   succeeded: boolean;
   data: T;
   error: ApiError;
}

export type ApiCallback<T> = (response: ApiResponse<T>) => void;

export function APIError(error: ApiError): ApiResponse<null> {
   return { succeeded: false, data: null, error };
}

export interface CommerceData {
   name: string;
   address: string;
   contact_name: string;
   contact_email: string;
   contact_phone: string;
   contact_mobile_phone: string;
   type: string;
   agency_id: number;
   intermediary_id: number;
   automatic_closure_enabled: number;
   agency_invoicers: {
      id: number;
      invoicer_id: number;
      configuration_parameters: string[];
      payment_methods: {
         id: number;
         acquirer_id: number;
      }[]
   }[];
}

export interface UserData {
   username?: string;
   email: string;
   full_name: string;
   enterprise: string;
   permission_ids: number[];
   agency_ids: number[];
   roles_ids: number[];
}

export interface TerminalConfigurationData {
   id: number;
   invoicer_id: number;
   acquirer_id: number;
   host_ip: string;
   rnc: string;
   description: string;
   url: string;
   port: number;
   delay: number;
   register_id: number;
   ssl_enabled: boolean;
   process_card_ext: boolean;
   print_hint: boolean;
   prepaid_enabled: boolean;
   postpaid_enabled: boolean;
   partial_payment_enabled: boolean;
   txn: number;
}

export interface DepositImageData {
   file_name: string;
   file_type: string;
   file_data: string;
}

export interface CommerceDepositData {
   method: 0 | 1 | 2;
   date_start: string | Date;
   date_end: string | Date;
   amount: number;
   reference: string;
   file_name: string;
   file_type: string;
   file_data: string;
}

export interface OperationAdjustmentData {
   type: 0 | 1;
   description: string;
   date: string | Date;
   reference: string;
   amount: number;
   file_name: string;
   file_type: string;
   file_data: string;
}

export interface UpdateContactCommerceRequest {
   contact_mobile_phone: string;
}
