import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Invoicer, Transaction } from 'src/app/shared/models/models';
import { DataService } from 'src/app/shared/services/data.service';
import { State } from 'src/app/shared/state.shared';
import {
  error_toast,
  success_toast,
} from 'src/app/utilities/toaster/toaster.utilities';
import { distinct } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-transaction-table',
  templateUrl: './transaction-table.component.html',
  styleUrls: [],
})
export class TransactionTableComponent {
  working = {
    nullifying: false,
  };
  @Output() filterTransations = new EventEmitter<Transaction[]>()
  @Output() isExtendedTable = new EventEmitter<boolean>()
  _transactions: Transaction[];
  @Input() get transactions(): Transaction[] { return this._transactions; }
  set transactions(value: Transaction[]) {
    this._transactions = value?value:[];
    this.invoicers = distinct(value.map(t => t.invoicer), i => i.id);
    this.ditails_invoicer_values = this.transactionsInvoicer_total(this.transactions);
  }

  invoicers: Invoicer[] = [];
  selected_invoicer: Invoicer[] = [];
  ditails_invoicer_values:{amount:number;value:number;name:string}[] = []

  pos_nameinvoicer = (pos: Invoicer) =>  pos.description;

  get transactions_total(): number {
    var filter = this.transactions?.filter(x=> x.response.code === 'P0000' || x.response.code === 'A0000' || x.response.code === 'RP000');
    return filter.length <= 0 ? 0 : (filter.map(t => t.amount) ?? [0]).reduce((a, b) => a + b);
  }

  transactionsInvoicer_total(transatios:Transaction[]): {amount:number;value:number;name:string}[] {
  // var invoicers = this.selected_invoicer.length !== 0? this.selected_invoicer: this.invoicers;
   return this.invoicers.map( invo =>{
      var amount = 0;
      var value = 0;
      transatios?.forEach(x =>{
        if((x.response.code === 'P0000' || x.response.code === 'A0000' || x.response.code === 'RP000') && x.invoicer.id === invo.id) value += x.amount;
        if(x.invoicer.id === invo.id) amount++;
      });
      return { amount : amount,value: value,name:invo.description} ;
    });
  }

  extended_table = false;
  include_queries = false;
  include_non_successes = false;
  filter_string: string;
  filter_type: number = 0;
  modal: number = 0;

  get filtered_transactions(): Transaction[] {
    let trans = this.transactions ?? [];
    if (this.filter_type !== 0)
      trans = trans.filter((t) =>
        this.filter_type == 1 ? !t.prepaid : t.prepaid
      );
      this.ditails_invoicer_values = this.transactionsInvoicer_total(trans);
    if (this.selected_invoicer.length>0)
      trans = trans.filter((t) => this.selected_invoicer.map(x =>x.id).includes(t.invoicer.id));
    if (!this.include_queries)
      trans = trans.filter((t) => ![1, 6].includes(t.type?.id));
    if (!this.include_non_successes)
      trans = trans.filter((t) => t.response.code === 'P0000' || t.response.code === 'A0000' || t.response.code === 'RP000' || t.response?.code === 'C0000');
      this.filterTransations.emit(trans);
      this.isExtendedTable.emit(this.extended_table);

    return trans;

  }

  transaction_to_nullify: Transaction;
  nullification_reason: string;

  constructor(private api: DataService, private router:Router) {}

  nullify_transaction() {
    this.working.nullifying = true;
    this.api.nullify_transaction(
      this.transaction_to_nullify.id,
      this.nullification_reason,
      (response) => {
        this.working.nullifying = false;
        if (response.succeeded) {
          this.transactions.find(
            (t) => t.id == this.transaction_to_nullify.id
          ).status.id = 4;
          success_toast('Transaccion anulada');
          this.transaction_to_nullify = null;
        } else error_toast(response.error.message);
      }
    );
  }

  go(commerce_code: string) {
    this.router.navigate(['transactions', 'points-of-sale', commerce_code]);
  }

  permiss() {
    return State.getUserPermiss('Anular transaccion');
 }

}
