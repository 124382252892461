import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { LostPaymentAmountDetails, Lot } from 'src/app/shared/models/models';
import { DataService } from 'src/app/shared/services/data.service';
import { error_toast } from 'src/app/utilities/toaster/toaster.utilities';

@Component({
  selector: 'app-open-lots',
  templateUrl: './open-lots.component.html',
  styleUrls: []
})
export class OpenLotsComponent {
  working = true;

  lots: Lot[];

  filter_string: string;
  filter_type: number = 0;

  get filtered_lots(): Lot[] {
    let lots = this.lots;
    if (this.filter_type !== 0) {
      lots = lots.filter(l => this.filter_type == 2? l.prepaid : !l.prepaid);
    }
    return lots;
  }

  constructor(api: DataService, private router:Router) {
    api.open_lots(response => {
      this.working = false;
      if (response.succeeded) {
        this.lots = response.data;
      } else error_toast(response.error.message);
    });
  }

  go(commerce_code: string) {
    this.router.navigate(['transactions', 'points-of-sale', commerce_code]);
  }
}
