<h2 class="mb-6">Cartera de Cobro</h2>

<div class="card w-full">
   <div class="flex flex-row">
      <div class="mr-8">
         <div>Agencia:</div>
         <select [(ngModel)]="selected_agency_id" (change)="handle_agency_selection()" class="min-w-[300px] border rounded py-1 px-2 border-gray-400">
            <option *ngIf="!selected_agency_id" disabled>(Seleccionar agencia)</option>
            <option *ngFor="let agency of agencies" [value]="agency.id">{{ agency.description }}</option>
         </select>
      </div>
      <div>
         <div>Punto de Venta / Cobrador:</div>
         <select [(ngModel)]="selected_commerce_id" (change)="handle_commerce_selection()" class="min-w-[300px] border rounded py-1 px-2 border-gray-400">
            <option *ngIf="!selected_commerce_id" disabled>(Seleccionar comercio)</option>
            <option *ngFor="let commerce of agency_commerces" [value]="commerce.id">{{ commerce.name }}</option>
         </select>
      </div>
   </div>

   <div class="mt-6">
      <div class="flex flex-row">
         <table-control #clientes for=clientes [source]=portfolio [filter]=filter class="flex-1"></table-control>
         <input type="text" [(ngModel)]=filter placeholder="🔍 Filtrar..." class="w-80 border rounded px-2 mb-2 border-gray-400">
      </div>
      <table>
         <thead>
            <th>Código</th>
            <th>Nombre</th>
            <th>Sector</th>
            <th>Barrio</th>
            <th>Día de cobro</th>
            <th>Plan</th>
         </thead>
         <tbody>
            <tr *ngFor="let client of clientes.slice">
               <td>{{ client.client_number }}</td>
               <td>{{ client.client_name }}</td>
               <td>{{ client.zone }}</td>
               <td>{{ client.town }}</td>
               <td>{{ client.payment_day }}</td>
               <td>{{ client.plan }}</td>
            </tr>
         </tbody>
      </table>
      <table-paginator class="mt-2" for=clientes></table-paginator>
   </div>
</div>
