import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { set_toast_dock } from './toaster.utilities';

@Component({
  selector: 'toaster',
  templateUrl: './toaster.component.html',
  styleUrls: []
})
export class ToasterComponent implements AfterViewInit {
  top_or_bottom: 'top' | 'bottom' = 'bottom';
  center_left_or_right: 'center' | 'left' | 'right' = 'center';

  @Input() set top(value: string | boolean) {
    if (value === '' || value)
      this.top_or_bottom = 'top';
  }
  @Input() set bottom(value: string | boolean) {
    if (value === '' || value)
      this.top_or_bottom = 'bottom';
  }

  @Input() set center(value: string | boolean) {
    if (value === '' || value)
      this.center_left_or_right = 'center';
  }
  @Input() set left(value: string | boolean) {
    if (value === '' || value)
      this.center_left_or_right = 'left';
  }
  @Input() set right(value: string | boolean) {
    if (value === '' || value)
      this.center_left_or_right = 'right';
  }

  @ViewChild('dock') dock: ElementRef<HTMLDivElement>;

  constructor() { }
  ngAfterViewInit(): void {
    set_toast_dock(this.dock.nativeElement);
  }
}
