import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommerceDetailed, Transaction } from 'src/app/shared/models/models';
import { DataService } from 'src/app/shared/services/data.service';
import { error_toast, success_toast } from 'src/app/utilities/toaster/toaster.utilities';
import { export_text_file, export_xlsx_file, pad_zeroes, sum, getDates } from 'src/app/utilities/utilities';

import '../../../utilities/extensions';


@Component({
   selector: 'app-point-of-sale',
   templateUrl: './point-of-sale.component.html',
   styleUrls: ['./point-of-sale.component.less']
})
export class PointOfSaleComponent {
   commerce = <CommerceDetailed>{
      id: 0,
      code: "",
      name: "",
      address: "",
      type: "",
      enabled: null,
      contact_name: "",
      contact_email: "",
      contact_phone: "",
      contact_mobile_phone: "",
      agency: null,
      intermediary: null,
      agency_invoicers: null,
   };

   start_date: string | Date;
   end_date: string | Date;
   today: string | Date;

   working = false;

   transactions: Transaction[];
   transactionsFilter: Transaction[] = [];
   isExtendedTable = false;

   constructor(private router: Router, private api: DataService, active_route: ActivatedRoute) {
      const today: Date = new Date(Date.now());
      today.setHours(0, 0, 0, 0);
      this.today = `${today.getFullYear()}-${pad_zeroes(today.getMonth() + 1)}-${pad_zeroes(today.getDate())}`;
      this.start_date = this.end_date = this.today;

      active_route.params.subscribe(params => {
         api.commerce_detailed(params.id, response => {
            if (response.succeeded) {
               this.commerce = response.data;
            } else error_toast(response.error.message);
         });
      });
   }

   back() { this.router.navigate(['transactions', 'points-of-sale']) }

   async query_transactions() {
      this.working = true;
      try {
         this.transactions = await this.api.transactions_by_commerce(this.commerce.id, this.start_date, this.end_date);
         this.working = false;
      } catch (e) {
         if (e instanceof Error) {
            error_toast(e.message);
            this.working = false;
         }
      }
   }

   change_enabled(enabled: boolean) {
      //this.working = true;
      this.api.active_commerce(this.commerce.id, enabled, response => {
         //this.working = false;
         if (response.succeeded) {
            success_toast('Comercio actualizado');
            this.commerce.enabled = enabled;
         } else error_toast(response.error.message);
      })
   }

   exportar_excel() {
      // const transactions = this.transactionsFilter.filter(t => t.response.code.endsWith('000') && !t.response.code.includes('C'));;
      const filas: (string | number)[][] = [];
      var headers: Array<string> = []

      if (this.isExtendedTable) {
         headers = [
            "Comercio",
            "Modalidad",
            "Tipo",
            "Cliente",
            "Monto",
            "Facturador",
            "Modo Pago",
            "Doc. Pago",
            "Respuesta",
            "Fecha",
            "Lote",
            "Secuencia",
            "Número Transacción"
         ];	
      } else {
         headers = [
            "Comercio",
            "Modalidad",
            "Tipo",
            "Cliente",
            "Monto",
            "Facturador",
            "Modo Pago",
            "Fecha",
            "ID transacción",
            "Estado",
            "Respuesta"
         ];	
      }


      this.transactionsFilter.forEach(t => filas.push(this.generarFila(t)));

      export_xlsx_file(this.commerce.name, headers, filas);
   }

   generarFila(t: Transaction): (string | number)[] {
      var fila: (string | number)[] = [];

      if (this.isExtendedTable) {
         fila = [
            t.commerce.description,
            t.prepaid ? "PREPAGO" : "POSPAGO",
            t.type.description,
            t.client,
            t.amount,
            t.invoicer.description,
            t.payment_method.description,
            t.payment_document,
            t.response.code,
            typeof t.date === 'string' ? t.date : formatDate(t.date, 'yyyy-MM-dd HH:mm:ss', 'es-DO'),
            t.lot,
            t.sequence,
            t.id
         ];
      } else {
         fila = [
            t.commerce.description,
            t.prepaid ? "PREPAGO" : "POSPAGO",
            t.type.description,
            t.client,
            t.amount,
            t.invoicer.description,
            t.payment_method.description,
            typeof t.date === 'string' ? t.date : formatDate(t.date, 'yyyy-MM-dd HH:mm:ss', 'es-DO'),
            t.id,
            t.status.id != 3 ? "EXITOSA" : "FALLIDA",
            t.response.code
         ];
      }

      return fila;
   }

   exportar_csv_total() {
      const transactions =  this.transactionsFilter.filter(t => t.response.code.endsWith('000') && !t.response.code.includes('C'));
      const invoicers = transactions.map(t => t.invoicer).distinct(i => i.id);
      const filas: (string | number)[][] = [];

      filas.push([this.commerce.name]);
      filas.push(["Pospago"]);
      filas.push(['Dia', ...invoicers.map(i => i.description), 'Total']);
      var pospaid_transactions = transactions.filter(t => !t.prepaid);
      this.generarFilas(pospaid_transactions.group(t => formatDate(t.date, 'yyyy-MM-dd', 'es-DO')),filas,invoicers);
      filas.push([""]);
      filas.push(["Prepago"]);
      filas.push(['Dia', ...invoicers.map(i => i.description), 'Total']);
      var prepaid_transactions = transactions.filter(t => t.prepaid);
      this.generarFilas(prepaid_transactions.group(t => formatDate(t.date, 'yyyy-MM-dd', 'es-DO')),filas,invoicers);
      export_text_file(this.commerce.name + '.csv', 'sep=;\n' + filas.map(celdas => celdas.map(c => typeof c === 'string'? `"${c}"` : c).join(';')).join('\n'));
   }

   generarFilas(daily: { key: string; items: Transaction[];}[],filas:(string | number)[][],invoicers:{id: number; description: string;}[]){
   var days = getDates( this.start_date, this.end_date)

    for( let day of days){
      var index =  daily.find( d => d.key  == day)
      if( index){
        filas.push([
          index.key,
          ...invoicers.map(i => Number(sum(index.items.filter(t => t.invoicer.id == i.id).map(t => t.amount)).toFixed(2))),
          Number(sum(index.items.map(t => t.amount)).toFixed(2))
        ]);
      }else {
        filas.push([
          day.toString(),
          ...invoicers.map(i => 0),
          0.00])
      }
    }
  }

  GetFilter(transations:Transaction[]){
    this.transactionsFilter = transations;
  }

  toogleExtendedTable(isExtended: boolean){
      this.isExtendedTable = isExtended;
      console.log(this.isExtendedTable);
  }

}
