
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';


export function pad_zeroes(value: any, length: number = 2): string {
   value = value.toString();
   if (value.length >= length)
      return value;
   else {
      return '0'.repeat(length - value.length) + value;
   }
}

export function today(): Date {
   const now = new Date(Date.now());
   now.setHours(0, 0, 0, 0);
   return now;
}

export function format_date_iso(date: string | Date): string {
   if (typeof date == 'string')
      date = new Date(date);
   return `${date.getFullYear()}-${pad_zeroes(date.getMonth() + 1)}-${pad_zeroes(date.getDate())}`;
}

export function distinct<T, K>(collection: T[], key?: (obj: T) => K): T[] {
   if (key)
      return collection?.filter((v, i, a) => a.findIndex(_ => key(_) == key(v)) === i);
   return collection?.filter((v, i, a) => a.indexOf(v) === i);
}

export function titlecase(text: string, language: 'es' | 'en' = 'es'): string {
   const titlecased = text?.split(' ')?.map(w => w?.length? w[0].toUpperCase() + w.substr(1).toLowerCase() : '')?.join(' ');
   switch (language) {
      case 'es':
         return titlecased
            .replace(/ De /g, ' de ');
      case 'en':
         return titlecased
            .replace(/ Of /g, ' of ');
   }
}

export function pascal_case_to_sentence(text: string): string {
   return text?.replace(/(?<!^)([A-Z])/g, ' $1');
}

export function export_text_file(filename: string, contents: string) {
   var element = document.createElement('a');
   element.href = `data:text/plain;charset=utf-8,${encodeURIComponent(contents)}`;
   element.download = filename;
   element.style.display = 'none';
   document.body.appendChild(element);
   element.click();
   document.body.removeChild(element);
}

export function export_xlsx_file(filename: string, headers: string[], data: any[][]) {
   const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
   const wb: XLSX.WorkBook = XLSX.utils.book_new();

   // Añadir filtros a las columnas
   ws['!autofilter'] = {ref:"A1:Z1"};

   XLSX.utils.book_append_sheet(wb, ws, "Hoja 1");

   // Guardar el archivo
   XLSX.writeFile(wb, filename + '.xlsx');
}


export function getDates(startDateValue:string | Date, stopDateValue:string | Date) {
   var dateArray:String[] = [];
   var star = startDateValue.toLocaleString().split('-')
   var stop = stopDateValue.toLocaleString().split('-')
   var currentDate = new Date( Number.parseInt(star[0]) , (Number.parseInt(star[1])-1),Number.parseInt(star[2]));
   var stopDate = new Date( Number.parseInt(stop[0]) ,(Number.parseInt(stop[1])-1),Number.parseInt(stop[2]));

   while (currentDate <= stopDate) {
       dateArray.push(formatDate(currentDate, 'yyyy-MM-dd', 'es-DO'));
       currentDate.setDate(currentDate.getDate() + 1);
   }
  return dateArray;
  }


export function export_file(filename: string, dataurl: string) {
   var element = document.createElement('a');
   element.href = dataurl;
   element.download = filename;
   element.style.display = 'none';
   document.body.appendChild(element);
   element.click();
   document.body.removeChild(element);
}

export function sum(collection: number[]): number {
   return (collection ?? [0]).concat(0).reduce((a, b) => a + b);
}