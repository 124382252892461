export interface Validator {
   accepts: (value: string) => boolean;
   error_message: string;
}

export interface ValidatedFieldState {
   valid: boolean;
   error: string;
}

export interface ValidatedField {
   id: string;
   state: ValidatedFieldState;
   validators: Validator[];
}

export class ValidationManager {
   fields: { [field: string]: ValidatedField } = {};
   valid:  { [field: string]: boolean } = {};
   error:  { [field: string]: boolean } = {};

   all_valid(): boolean {
      const fields = Object.values(this.fields);
      fields.forEach(check);
      return fields.every(f => f.state.valid);
   }

   check(...field_names: string[]): boolean {
      const fields = field_names.map(f => this.fields[f]);
      fields.forEach(check);
      return fields.every(f => f.state.valid);
   }
}

function check(field: ValidatedField): boolean {
   const value = value_of(field.id);
   for (let validator of field.validators) {
      if (!validator.accepts(value)) {
         field.state.valid = false;
         field.state.error = validator.error_message;
         return false;
      }
   }
   field.state.valid = true;
   field.state.error = null;
   return true;
}

function value_of(field: string): string {
   return input_by_id(field)?.value;
}

function input_by_id(id: string): HTMLInputElement | HTMLTextAreaElement {
   const element = document.getElementById(id);
   if (!element) return null;
   if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
      return <HTMLInputElement|HTMLTextAreaElement>element;
   } else {
      let inner_inputs: HTMLCollectionOf<HTMLInputElement | HTMLTextAreaElement> = element.getElementsByTagName('input');
      if (inner_inputs.length) return inner_inputs[0];
      else {
         inner_inputs = element.getElementsByTagName('textarea');
         if (inner_inputs.length) return inner_inputs[0];
         return null;
      }
   }
}

// 

export function validator(validations: { [field: string]: Validator[] }): ValidationManager {
   const manager = new ValidationManager();
   for (let id in validations) {
      const field = { id, state: { valid: true, error: <string>null }, validators: validations[id] };
      manager.fields[id] = field;
      Object.defineProperty(manager.error, id, { get: () => field.state.error });
      Object.defineProperty(manager.valid, id, { get: () => field.state.valid });

      input_by_id(id)?.addEventListener('focusout', () => check(field));
   }
   return manager;
}

export function validate(validity_checker: (value: string) => boolean, error_message: string = 'Inválido'): Validator {
   return { accepts: validity_checker, error_message };
}

export const validation = {
   required: validate(_ => _ !== null && _ !== undefined && _.length > 0, 'Requerido'),
   optional: (...validations: Validator[]) => validations.map(v => validate(_ => _ === null || _ === undefined || _.length === 0 || v.accepts(_), v.error_message)),
   conditional: (condition: () => boolean, ...validations: Validator[]) => validations.map(v => validate(_ => !condition() || v.accepts(_), v.error_message)),

   length: (minimum: number, maximum?: number) => validate(_ => _.length >= minimum && (maximum? _.length <= maximum : true), `Debe tener ${maximum? (maximum != minimum? 'de' : '') : 'al menos'} ${minimum}${maximum? (maximum != minimum? ` a  ${maximum}` : '') : ''} caracteres`),
   email: validate(_ => (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(_), 'Correo inválido'),
   phone: validate(_ => (/^\(?[0-9]{3}\)?[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/).test(_), 'Teléfono inválido'),
};