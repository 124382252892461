<div class="login-container">
   <div>
      <h1 class="text-indigo-900 text-center"><span class="text-red-500">Gestión</span> de Agencias</h1>
      <div class="card w-[450px]">
         <h2>Iniciar Sesión</h2>

         <div class="my-4">
            <label for=username>Usuario</label>
            <div class="input" [class.invalid]=!fields.valid.username>
               <input type=text id=username [(ngModel)]=data.username (keydown.enter)=password.focus()>
            </div>
            <div class="field-error">{{ fields.error.username }}</div>
         </div>

         <div class="my-4">
            <label for=password>Contraseña</label>
            <div class="input" [class.invalid]=!fields.valid.password>
               <input #password id=password type="{{ !show_password? 'password' : 'text' }}" [(ngModel)]=data.password (keydown.enter)=login()>
               <fa-icon [icon]="['far', !show_password? 'eye' : 'eye-slash']" (click)="show_password = !show_password"
                  class="px-3 cursor-pointer text-blue-700 opacity-80"></fa-icon>
            </div>
            <div class="field-error">{{ fields.error.password }}</div>
         </div>

         <div class="text-center mb-2">
            <!-- <a>Olvidé mi contraseña</a> -->
            <a href="https://gestionagencias.transnegrd.com/" class="">Gestión de agencia anterior</a>
         </div>

         <button (click)=login() class="btn blue w-full">
            Ingresar
         </button>
      </div>
   </div>
</div>