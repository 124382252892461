
<div class="flex justify-end">
  <h5 class="felx">{{ fecha | date :'dd/MM/yyyy'}}</h5>
</div>
<img src='./assets/logos/{{logo}}' class="h-auto " alt="..." style="width: 300px; margin: auto; padding: 25px;" />

<div class="m-4 text-sm" >
  <h2 class="my-4 text-center">Descuento de pago</h2>
    <div class="flex justify-between">
      <h3  class="pt-4">➤ Descuento</h3>
      <div class="pt-4">
        <span *ngIf="discount.order.description.toLowerCase().indexOf('proyecto') >= 0">
          Expediente
          <strong>
            {{ discount.order.description.substring(discount.order.description.toLowerCase().indexOf('proyecto')+8,discount.order.description.length) }}
          </strong>
        </span>
      </div>
      </div>
    <hr class="w-[100%] border-solid border-2">
    <div class="w-100   flex justify-between p-4">
      <div class="flex itemsBox content-around h-[80px]">
        <div class="flex pl-4">
          <p class="mr-4 font-semibold">Aprobado por:  </p>
          <span>
            {{ discount.user.name }}
          </span>
        </div>
        <div class="flex pl-4">
          <p class="font-semibold mr-4">Fecha:  </p>
          <span>
            {{ discount.date | date :'dd/MM/yyyy' }}
          </span>
        </div>
      </div>
      <div class="flex itemsBox content-around h-[80px] max-w-[50%]">
        <div class=" flex pl-4">
          <p class="font-semibold mr-4">Monto:  </p>
          <span>
           RD$ {{ discount.amount | currency:' ' }}
          </span>
        </div>
        <div class=" flex pl-4 ">
          <p class="font-semibold mr-4">Observación</p>
          <span>
            {{ discount.observation  }}
          </span>
        </div>
      </div>
      <div class="flex itemsBox content-around h-[80px]">
        <div class=" flex pl-4">
          <p class="font-semibold mr-4">No. </p>
          <span>
            {{ discount.id }}
          </span>
        </div>
      </div>
    </div>
    <h3 class="pt-4">➤ Orden</h3>
    <hr class="w-[100%] border-solid border-2">
    <div class="w-100 flex justify-between p-4">
      <div class="flex itemsBox content-around h-[85px]">
        <div class="flex pl-4">
          <p class="mr-4 font-semibold">Cliente:   </p>
          <span>
            {{ discount.order.name }}
          </span>
        </div>
        <div class="flex pl-4">
          <p class="font-semibold mr-4">Monto Original: </p>
          <span>
            RD$ {{ discount.original | currency:' ' }}
          </span>
        </div>
        <div class="flex pl-4">
          <p class="font-semibold mr-4">Descripción: </p>
          <span *ngIf="discount.order.description.toLowerCase().indexOf('proyecto') >= 0; else elseBlock">
              {{ discount.order.description.substring(0,(discount.order.description.toLowerCase().indexOf('proyecto')-2)) }}
          </span>
          <ng-template #elseBlock>
            {{ discount.order.description  }}
          </ng-template>
        </div>
      </div>
      <div class="flex itemsBox content-around h-[85px]">
        <div class=" flex pl-4">
          <p class="font-semibold mr-4">No.:  </p>
          <span>
            {{ discount.order.code  }}
          </span>
        </div>
        <div class=" flex pl-4">
          <p class="font-semibold mr-4">Monto Modificado: </p>
          <span>
            RD$ {{ discount.order.amount | currency:' ' }}
          </span>
        </div>
        <div class=" flex pl-4">
          <p class="font-semibold mr-4"></p>
          <span>
          </span>
        </div>
      </div>
      <div class="flex itemsBox content-around h-[85px]">
        <div class=" flex pl-4">
          <p class="font-semibold mr-4">Generada el:  </p>
          <span>
            {{ discount.order.date_created | date :'dd/MM/yyyy' }}
          </span>
        </div>
      </div>
    </div>
    <h3  class="pt-4">➤ Punto de Venta</h3>
    <hr class="w-[100%] border-solid border-2">
    <div class="w-100   flex justify-between p-4">
      <div class="flex itemsBox content-around h-[80px]">
        <div class="flex pl-4">
          <p class="mr-4 font-semibold">Nombre: </p>
          <span>
            {{ discount.order.comerce }}
          </span>
        </div>
      </div>
      <div class="flex itemsBox content-around h-[80px]">
        <div class=" flex pl-4">
          <p class="font-semibold mr-4">Direccion:  </p>
          <span>
            {{ discount.order.comerce_address }}
          </span>
        </div>
    </div>
  </div>
<div class="m-9 text-center">=== Fin del documento ===</div>
