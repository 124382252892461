import { Component } from '@angular/core';
import { Transaction } from 'src/app/shared/models/models';
import { DataService } from 'src/app/shared/services/data.service';
import { error_toast } from 'src/app/utilities/toaster/toaster.utilities';

@Component({
   selector: 'app-contract-view',
   templateUrl: './contract-view.component.html',
   styleUrls: ['./contract-view.component.less']
})
export class ContractViewComponent {
   working = false;

   contract_id: string;

   transactions: Transaction[];

   extended_table        = false;
   include_queries       = false;
   exclude_non_successes = false;
   filter_string: string = null;
   filter_type: number = 0;

   get filtered_transactions(): Transaction[] {
      let trans = this.transactions ?? [];
      if (this.filter_type !== 0)
      trans = trans.filter((t) =>
        this.filter_type == 1 ? !t.prepaid : t.prepaid
      );
      if (!this.include_queries)
         trans = trans.filter(t => ![1, 6].includes(t.type?.id));
      if (this.exclude_non_successes)
         trans = trans.filter(t => t.response?.code === 'P0000' || t.response?.code === 'A0000' || t.response?.code === 'RP000' || t.response?.code === 'C0000');
      return trans;
   }

   constructor(private data: DataService) { }

   query() {
      this.working = true;
      this.data.transactions_by_client_reference(this.contract_id, response => {
         this.working = false;
         if (response.succeeded) {
            this.transactions = response.data;
         } else error_toast(response.error.message);
      });
   }
}
