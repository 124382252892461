<!-- Sidebar -->
<div class="side-bar group">
   <!-- Logo/Título -->
   <div class="flex flex-row items-center justify-center h-16 text-indigo-900">
      <h2 class="group-hover:hidden"><span class="text-red-500">G</span>A</h2>
      <h2 class="hidden group-hover:block whitespace-nowrap overflow-hidden"><span class="text-red-500">Gestión</span> de Agencias</h2>
   </div>
   <!-- Menú -->
   <div class="p-6">
      <div *ngFor="let item of menu">
         <!-- Item superior -->
         <div (click)="go_or_select(item)" class="menu-item" [class.active]=active(item)>
            <fa-icon [icon]=item.icon></fa-icon>
            <div class="label hidden group-hover:block">{{ item.label }}</div>
            <fa-icon *ngIf="item.children?.length" [icon]="selected(item)? 'chevron-down' : 'chevron-right'"
               class="expander hidden group-hover:block"></fa-icon>
         </div>
         <div *ngIf="item.children?.length && selected(item)" class="hidden group-hover:block pl-4">
            <div *ngFor="let child of item.children" class="my-1">
               <!-- Item sub -->
               <div (click)="go_or_select(child)" class="sub-menu-item" [class.active]=active(child)>
                  - {{ child.label }}
                  <fa-icon *ngIf="child.children?.length" [icon]="selected(child)? 'chevron-down' : 'chevron-right'"
                     class="text-xs"></fa-icon>
               </div>
               <div *ngIf="child.children?.length && selected(child)" class="hidden group-hover:block pl-4">
                  <!-- Item sub-sub -->
                  <div *ngFor="let inner_child of child.children"
                     class="cursor-pointer hover:text-blue-800">
                     {{ inner_child.label }}
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- Topbar -->
<div class="top-bar">
   <div class="flex flex-row items-center">
      <!-- User menu -->
      <div class="group user-menu">
         <img src="/assets/profile.jpg" class="h-10 rounded-lg">
         <div class="ml-4">
            <div class="font-semibold">{{ app_state.user.username }}</div>
            <div class="text-xs">
               <fa-icon icon="chevron-down"></fa-icon>
            </div>
         </div>
         <!-- Hover menu -->
         <div class="hover-menu hidden group-hover:block">
            <a [routerLink]="['admin/Perfil/details']" class="hover-menu-item" style="text-decoration: none;color: black;">
               <fa-icon icon="key" class="mr-2"></fa-icon>
               <span>Cambiar clave</span>
            </a>
            <div (click)="logout()" class="hover-menu-item">
               <fa-icon icon="sign-out-alt" class="mr-2"></fa-icon>
               <span>Salir</span>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- Contenido -->
<div class="content">
   <router-outlet></router-outlet>
</div>
