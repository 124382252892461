<!-- Resúmen -->
<div class="border-b border-t pt-4 mb-6 mt-7 flex flex-row flex-wrap">
  <div class="mr-8 mb-4">
     <div><span class="font-medium">Cant. transacciones: </span> <span class="text-indigo-800">{{ transactions.length }}</span></div>
     <div><span class="font-medium">Total transacciones: </span> <span class="text-indigo-800">{{ transactions_total | currency:'RD$ ' }}</span></div>
  </div>
  <div *ngFor="let item of ditails_invoicer_values" class="mr-8 mb-4">
     <div *ngIf="item.amount > 0"><span class="font-medium">Cant. trans. {{ item.name }}: </span> <span class="text-indigo-800">{{item.amount}}</span></div>
     <div *ngIf="item.amount > 0"><span class="font-medium">Total {{ item.name }}:        </span> <span class="text-indigo-800">{{item.value | currency:'RD$ ' }}</span></div>
  </div>
</div>

<table-control #transacciones for="transacciones" fem [source]="filtered_transactions" [filter]="filter_string"></table-control>
<!-- Filtros -->
<div class="flex flex-row items-baseline border-t space-x-8">
   <div (click)="extended_table = !extended_table" class="cursor-pointer">
      <fa-icon [icon]="extended_table? 'toggle-on' : 'toggle-off'" class="align-middle text-lg {{ extended_table? 'text-indigo-800' : '' }}"></fa-icon>
      Incluir detalles
   </div>
   <div (click)="include_queries = !include_queries" class="cursor-pointer">
      <fa-icon [icon]="include_queries? 'toggle-on' : 'toggle-off'" class="align-middle text-lg {{ include_queries? 'text-indigo-800' : '' }}"></fa-icon>
      Incluir consultas
   </div>
   <div (click)="include_non_successes = !include_non_successes" class="cursor-pointer">
      <fa-icon [icon]="include_non_successes? 'toggle-on' : 'toggle-off'" class="align-middle text-lg {{ include_non_successes? 'text-indigo-800' : '' }}"></fa-icon>
      Incluir fallidas
   </div>
   <div class="flex-1"></div>
   <input [(ngModel)]=filter_string placeholder="🔍 Filtrar..." class="border rounded mt-2" >
</div>
<div class="flex flex-row items-baseline border-t border-b space-x-8 mt-4 justify-between">
  <div class="flex flex-row my-3">
    <div class="mr-4">Modalidad:</div>
    <div class="mr-4">
      <input type="radio" [value]="0" name="type" id="type-0" [(ngModel)]="filter_type" class="mr-1 cursor-pointer align-middle">
      <label for="type-0" class="cursor-pointer align-middle">Todos</label>
    </div>
    <div class="mr-4">
      <input type="radio" [value]="1" name="type" id="type-1" [(ngModel)]="filter_type" class="mr-1 cursor-pointer align-middle">
      <label for="type-1" class="cursor-pointer align-middle">Pospago</label>
    </div>
    <div>
      <input type="radio" [value]="2" name="type" id="type-2" [(ngModel)]="filter_type" class="mr-1 cursor-pointer align-middle">
      <label for="type-2" class="cursor-pointer align-middle">Prepago</label>
    </div>
  </div>
  <div class="flex items-center">
    <h4 class="">Facturadores:</h4>
    <multi-select class="border border-gray-200 rounded ml-2 mt-1"  [items]=invoicers [(selection)]="selected_invoicer" [converter]=pos_nameinvoicer></multi-select>
  </div>
</div>

<table-paginator class="mb-2 mt-2" for="transacciones"></table-paginator>
<table class="my-2 {{ extended_table? 'text-sm' : '' }}">
   <thead>
      <th></th>
      <th>Modalidad</th>
      <th>Tipo Tran.</th>
      <th>Cliente</th>
      <th colspan=3>
         <div class="min-w-max">Monto (RD$)</div>
      </th>
      <th>Facturador</th>
      <th>Modo Pago</th>
      <th *ngIf=extended_table>Doc. Pago</th>
      <th *ngIf=extended_table>Respuesta</th>
      <th>Fecha</th>
      <th>Punto de Venta</th>
      <th *ngIf=extended_table>Lote</th>
      <th *ngIf=extended_table>Sec.</th>
      <th *ngIf=extended_table>Nro. Transacción</th>
      <th></th>
   </thead>
   <tbody>
      <tr *ngFor="let transaction of transacciones.slice" class="{{ transaction.status.id === 5? 'bg-gray-200 hover:bg-gray-300' : 'hover:bg-gray-100' }}">
         <td class="text-center"
            [class.bg-green-400]="transaction.status.id == 2"
            [class.bg-yellow-400]="transaction.status.id === 4"
            [class.bg-red-400]="transaction.status.id === 3"
            [class.bg-gray-300]="transaction.status.id === 5">
            <fa-icon [icon]="[2,5].includes(transaction.status.id)? 'thumbs-up' : transaction.status.id === 4? 'eraser' : transaction.status.id == 1? 'hourglass-half' : 'times-circle'"></fa-icon>
         </td>
         <td>{{transaction.prepaid? 'Prepago' : 'Pospago'}}</td>
         <td>{{ transaction.type?.description }}</td>
         <td>{{ transaction.client }}</td>
         <td class="w-0 pr-2">$</td>
         <td class="w-0 text-right">
            <div class="min-w-max">{{ transaction.amount | currency:' ' }}</div>
         </td>
         <td></td>
         <td>{{ transaction.invoicer?.description }}</td>
         <td>{{ transaction.payment_method?.description }}</td>
         <td *ngIf=extended_table>{{ transaction.payment_document }}</td>
         <td *ngIf=extended_table>{{ transaction.response?.code }}</td>
         <td>{{ transaction.date | date:'dd/MMM/yyyy hh:mm aa':null:'es-DO' }}</td>
         <td (click)=go(transaction.commerce?.id)
         class="cursor-pointer hover:text-indigo-600">{{ transaction.commerce?.description }}</td>
         <td [routerLink]="'/transactions/lot/' + (transaction.prepaid? 'prepaid' : 'postpaid') + '/' + transaction.lot"
              *ngIf=extended_table class="cursor-pointer hover:text-indigo-800">{{ transaction.lot }}</td>
         <td *ngIf=extended_table>{{ transaction.sequence }}</td>
         <td *ngIf=extended_table>{{ transaction.id }}</td>
         <td class="w-0 !px-0">
          <button *ngIf="[2,7].includes(transaction.type.id) && transaction.status.id === 2 && transaction.response?.code === 'P0000' && permiss()" (click)="transaction_to_nullify = transaction" class="btn !py-0 min-w-max">
               Anular
               <fa-icon icon=eraser></fa-icon>
            </button>
         </td>
      </tr>
   </tbody>
</table>
<table-paginator class="mt-2" for="transacciones"></table-paginator>

<!-- Anular -->
<div *ngIf="transaction_to_nullify" class="fixed inset-y-0 inset-x-0 bg-gray-700 bg-opacity-10 flex flex-col justify-center items-center">
   <div class="card">
      <h3 class="mb-6">Anular transacción {{ transaction_to_nullify.id }}</h3>

      <div class="info-list">
         <div class="row">
            <div class="label">Comercio:</div>
            <div class="value">{{ transaction_to_nullify.commerce?.description }}</div>
         </div>
         <div class="row">
            <div class="label">Fecha:</div>
            <div class="value">{{ transaction_to_nullify.date | date:'dd/MMM/yyyy hh:mm aa':null:'es-DO' }}</div>
         </div>
         <div class="row">
            <div class="label">Cliente:</div>
            <div class="value">{{ transaction_to_nullify.client }}</div>
         </div>
         <div class="row">
            <div class="label">Monto:</div>
            <div class="value">{{ transaction_to_nullify.amount | currency:'RD$ ' }}</div>
         </div>
         <div class="row">
            <div class="label">Razón de anulación:</div>
            <div class="value">
               <input type=text [(ngModel)]="nullification_reason" class="border border-gray-300 rounded w-80">
            </div>
         </div>
      </div>

      <div class="flex flex-row justify-end mt-6">
         <button (click)="transaction_to_nullify = null" class="btn mr-2">
            <fa-icon icon=times></fa-icon>
            Cancelar
         </button>
         <button (click)="nullify_transaction()" class="btn yellow">
            <fa-icon icon=eraser></fa-icon>
            Anular
         </button>
      </div>
   </div>
</div>
