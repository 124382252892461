import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { Agency, ClientContract, CommerceInfo, Lot } from 'src/app/shared/models/models';
import { DataService } from 'src/app/shared/services/data.service';
import { error_toast } from 'src/app/utilities/toaster/toaster.utilities';
import { format_date_iso, pad_zeroes } from 'src/app/utilities/utilities';

@Component({
   selector: 'app-closed-lots',
   templateUrl: './closed-lots.component.html',
   styleUrls: []
})
export class ClosedLotsComponent {
   working = false;
   agencies: Agency[];
   selected_agency_id: number = 0;
   agency_commerces_cache: { [key: number]: CommerceInfo[] } = { };

   agency_commerces: CommerceInfo[];
   selected_commerce_id: number = 0;

   date_start: string | Date;
   date_end: string;
   today: string | Date;

   lots: Lot[] = [];
   filter_string: string;
   filter_type: number = 0;

   get filtered_lots(): Lot[] {
      if (this.filter_type == 0)
         return this.lots;
      else
         return this.lots?.filter(l => this.filter_type == 1? !l.prepaid : l.prepaid);
   }

   constructor(private api: DataService, private router:Router) {
      const today: Date = new Date(Date.now());
      today.setHours(0, 0, 0, 0);
      this.today = `${today.getFullYear()}-${pad_zeroes(today.getMonth() + 1)}-${pad_zeroes(today.getDate())}`;
      api.agencies(response => {
        if (response.succeeded) {
          this.agencies = response.data;
        } else error_toast(response.error.message);
      });
      this.date_start = this.date_end = this.today;
   }

   query() {
      this.working = true;
       var dia =  ((Date.parse(this.date_end.toString())  - Date.parse(this.date_start.toString()))/(1000 * 3600 * 24))
      if(dia > 1 && this.selected_commerce_id == 0 && this.selected_agency_id == 0){
        error_toast("Para consultar un rango de fecha mayo a un dia selecione el un comercio");
        this.working = false;
      }else if(dia > 31 && this.selected_agency_id != 0 && this.selected_commerce_id == 0 ){
        error_toast("ajustar los filtros a un rango de fecha menor o igual a un mes");
        this.working = false;
      }
      else{
        this.api.closed_lots( this.selected_agency_id,this.selected_commerce_id,this.date_start, this.date_end, response => {
          this.working = false;
          if (response.succeeded) {
            this.lots = response.data
          } else error_toast(response.error.message);
      });
      }
   }

   go(commerce_code: string) {
    this.router.navigate(['transactions', 'points-of-sale', commerce_code]);
  }

  handle_agency_selection() {
    const id = this.selected_agency_id;
    const cached_commerces = this.agency_commerces_cache[id];
    if (cached_commerces) {
      this.agency_commerces = cached_commerces;
    } else {
      this.api.agency_commerces(id, response => {
        if (response.succeeded) {
          this.agency_commerces_cache[id] = response.data;
          this.agency_commerces = response.data;
        } else error_toast(response.error.message);
      });
    }
  }

  reset(){
    this.date_start = this.date_end = this.today.toString();
    this.selected_agency_id = this.selected_commerce_id = 0;
    this.agency_commerces = [];
  }
}
