import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableControlComponent } from './table-control/table-control.component';
import { TablePaginatorComponent } from './table-control/table-paginator.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { LoaderComponent } from './loader/loader.component';
import { ToasterComponent } from './toaster/toaster.component';
import { FormsModule } from '@angular/forms';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { BusyComponent } from './busy/busy.component';
import { ModalComponent } from './modal.component';

@NgModule({
  declarations: [
    TableControlComponent,
    TablePaginatorComponent,
    MultiSelectComponent,
    LoaderComponent,
    ToasterComponent,
    BusyComponent,
    ModalComponent,
  ],
  imports: [CommonModule, FormsModule, FontAwesomeModule],
  exports: [
    TableControlComponent,
    TablePaginatorComponent,
    MultiSelectComponent,
    LoaderComponent,
    ToasterComponent,
    BusyComponent,
    ModalComponent,
  ],
})
export class UtilitiesModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
}
