<div (paste)="handlePastedContent($event)">
   <h1 class="mb-6">Gestion de Comercio<span *ngIf="commerce_operation"> {{ commerce_operation.name }}</span></h1>
   <div class="card">
      <div class="flex flex-row justify-end">
    
         <button (click)=back() class="btn">
            <fa-icon icon="arrow-left"></fa-icon>
            Volver
         </button>
   
      </div>
   
      <div class="tabs mb-2 flex flex-row justify-start">
         <div (click)="tab = 0" class="tab" [class.active]="tab == 0">Pendiente de Conciliación</div>
         <div (click)="tab = 1" class="tab" [class.active]="tab == 1">Estado de Cuenta</div>
         <div>
   
            <button class="btn bg-green-400 rounded font-medium ml-2 pt-2 mt-1 hover:bg-green-500" (click)="modal = 3">
               <fa-icon [icon]="['fab', 'whatsapp']"></fa-icon>
               Enviar vía whatsapp
            </button>
         </div>
   
      </div>
   
   
   
      <!-- Pendiente de consiliación -->
      <div *ngIf="tab == 0">
         <table class="w-full">
            <thead>
               <th>Movimiento</th>
               <th>Referencia</th>
               <th colspan="3">Monto</th>
               <th colspan="3">Pendiente</th>
            </thead>
            <tbody>
               <tr *ngFor="let mov of commerce_operation.pending_conciliation">
                  <td>
                     <div class="whitespace-normal max-w-[300px]">
                        {{ mov.description }}
                     </div>
                  </td>
                  <td>
                     <div class="whitespace-normal max-w-[300px]">
                        {{ mov.reference }}
                     </div>
                  </td>
                  <td class="w-0">RD$</td>
                  <td class="w-0 text-right">
                     <div class="min-w-max">{{ mov.amount | currency:' ' }}</div>
                  </td>
                  <td></td>
                  <td class="w-0 font-medium">RD$</td>
                  <td class="w-0 font-medium text-right">
                     <div class="min-w-max">{{ mov.pending | currency:' ' }}</div>
                  </td>
                  <td></td>
               </tr>
               <tr>
                  <td colspan="5" class="text-right">Balance actual del comercio:</td>
                  <td class="w-0 bg-gray-200 font-semibold">RD$</td>
                  <td class="w-0 bg-gray-200 font-semibold text-right">
                     <div class="min-w-max">{{ commerce_operation.balance | currency:' ' }}</div>
                  </td>
                  <td class="bg-gray-200"></td>
               </tr>
            </tbody>
         </table>
      </div>
   
      <!-- Estado de cuenta -->
      <div *ngIf="tab == 1">
         <div class="flex flex-row mb-4">
            <div class="mr-4">
               <div class="font-medium">Desde:</div>
               <input type=date [(ngModel)]="date_start" [max]="today" class="border border-gray-300 rounded">
            </div>
            <div class="mr-4">
               <div class="font-medium">Hasta:</div>
               <input type=date [(ngModel)]="date_end"   [max]="today" class="border border-gray-300 rounded">
            </div>
            <div class="mr-4">
               <button (click)="query()" class="btn blue mt-6 mr-3">
                  Ver estado
               </button>
               <button *ngIf="permissDeposit()" (click)="modal = 1" class="btn mt-6 mr-3">
                  <fa-icon icon=cash-register></fa-icon>
                  Registrar depósito
               </button>
   
               <button *ngIf="permissAjust()" (click)="modal = 2" class="btn yellow mt-6">
                  <fa-icon icon=wrench></fa-icon>
                  Ajuste extraordinario
               </button>
            </div>
         </div>
   
   
         <table class="mt-6 mb-4">
            <thead>
               <th>Total</th>
               <th>Depositado</th>
               <th>Ajuste</th>
               <th>Balance</th>
            </thead>
   
            <tbody>
               <tr>
                  <td>{{ total | currency:'RD$ '}}</td>
                  <td>{{ depositado | currency:'RD$ ' }}</td>
                  <td>{{ ajuste | currency:'RD$ ' }}</td>
                  <td class="bg-gray-200 font-medium m-5">{{ balance | currency:'RD$ ' }}</td>
               </tr>
            </tbody>
         </table>
   
   
         <table class="mt-6">
            <thead>
               <th>Fecha</th>
               <th *ngFor="let invoicer of filtered_invoicers">{{ invoicer.description }}</th>
               <th>Total por día</th>
            </thead>
   
            <tbody>
               <tr *ngFor="let date of dates; let i = index;">
                  <td>
                     {{ date }}
                  </td>
                  <td *ngFor="let _ of filtered_invoicers; let j = index;">
                     {{totals_per_day_and_invoicer[j][i] | currency:'RD$ ' }}
                  </td>
                  <td>{{ totals_per_day[i] | currency:'RD$ '}}</td>
               </tr>
   
               <tr>
                  <td>Totals:</td>
                  <td *ngFor="let _ of filtered_invoicers; let i = index;">{{ totals_per_invoicer[i] | currency:'RD$ ' }}</td>
               </tr> 
         
            </tbody>
         </table>
   
         <!-- Depósitos -->
         <h3 class="mt-6 mb-2">Depósitos</h3>
         <table class="mb-4">
            <thead>
               <th>Fecha</th>
               <th>Descripción</th>
               <th>Monto</th>
               <th>Archivo</th>
               <th></th>
            </thead>
   
            <tbody>
               <tr *ngFor="let deposit of deposits">
                  <td>{{ deposit.date | date:'yyyy-MM-dd HH:mm:ss' }}</td>
                  <td>{{ deposit.description }}</td>
                  <td>{{ -deposit.amount  | currency:'RD$ ' }}</td>
                  <td class="w-0">
                     <fa-icon icon=paperclip *ngIf="deposit.resource" (click)="retrieve_resource(deposit)" [title]="deposit.resource.filename" class="cursor-pointer"></fa-icon>
                  </td>
                  <td class="w-0">
                     <button (click)="delete_movement(deposit)" class="btn">
                        <fa-icon icon=trash></fa-icon>
                     </button>
                  </td>
               </tr>
            </tbody>
         </table>
   
   
         <!-- Ajustes -->
         <h3 class="mt-6 mb-2">Ajustes</h3>
         <table class="mb-4">
            <thead>
               <th>Fecha</th>
               <th>Descripción</th>
               <th>Monto</th>
               <th>Archivo</th>
               <th></th>
            </thead>
   
            <tbody>
               <tr *ngFor="let ajuste of ajustes">
                  <td>{{ ajuste.date | date:'yyyy-MM-dd HH:mm:ss' }}</td>
                  <td>{{ ajuste.description}}</td>
                  <td>{{ ajuste.amount  | currency:'RD$ ' }}</td>
                  <td class="w-0">
                     <fa-icon icon=paperclip *ngIf="ajuste.resource" (click)="retrieve_resource(ajuste)" [title]="ajuste.resource.filename" class="cursor-pointer"></fa-icon>
                  </td>
                  <td class="w-0">
                     <button (click)="delete_movement(ajuste)" class="btn">
                        <fa-icon icon=trash></fa-icon>
                     </button>
                  </td>
               </tr>
            </tbody>
         </table>
   
      </div>
   </div>
   
   <div *ngIf="modal" class="fixed inset-y-0 inset-x-0 bg-black bg-opacity-10 flex flex-col items-center justify-center">
   
      <!-- Registrar depósito -->
      <div *ngIf="modal == 1" class="card">
         <h3 class="mb-6">Registrar depósito a <span class="underline">{{ commerce_operation.name }}</span></h3>
   
         <div class="info-list w-full">
            <div class="row">
               <div class="label">Tipo de depósito:</div>
               <div class="value">
                  <select [(ngModel)]="data.type" class="border border-gray-300 rounded py-1 mb-3 w-full">
                     <option [value]="0">Efectivo</option>
                     <option [value]="1">Transferencia</option>
                     <option [value]="2">Tarjeta de Crédito</option>
                  </select>
               </div>
            </div>
            <div class="row">
               <div class="label">Desde:</div>
               <div class="value">
                 {{ date_start }}
               </div>
            </div>
            <div class="row">
               <div class="label">Hasta:</div>
               <div class="value">
                  {{ date_end }} 
               </div>
            </div>
            <div class="row">
               <div class="label">Monto del depósito:</div>
               <div class="value">
                  <div class="w-full flex flex-row items-baseline">
                     <input type=number  [(ngModel)]="data.amount" class="flex-1 border border-gray-300 rounded text-right mb-3" placeholder="0.00">
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="label">Referencia:</div>
               <div class="value">
                  <input type=text [(ngModel)]="data.reference" class="border border-gray-300 mb-4 rounded w-full">
               </div>
            </div>
            <div class="row">
               <div class="label">Archivo adjunto:</div>
               <div class="value">
                  <div (click)="file.click()" class="border border-gray-300 rounded py-1 w-full cursor-pointer text-center text-gray-700 hover:bg-blue-100">
                     <div *ngIf="!data.file_name">
                        <fa-icon icon=file-upload></fa-icon>
                        {{ !working.loading? 'Adjuntar archivo...' : 'Cargando...' }}
                     </div>
                     <div *ngIf="data.file_name">
                        <fa-icon [icon]="data.file_type.startsWith('image')? 'file-image' : data.file_type.endsWith('pdf')? 'file-pdf' : 'file'"></fa-icon>
                        {{ data.file_name.length > 25? (data.file_name.substr(0, 10).trim() + '...' + data.file_name.substr(data.file_name.length - 10, 10).trim()) : data.file_name }}
                     </div>
                  </div>
                  <input #file type=file accept="image/jpeg,image/png,.pdf" (input)="capture_file($event)" class="border border-gray-300 rounded w-full hidden">
                  <div>
                     <img class="m-3" src={{imageData}} alt="" style="max-width: 500px; max-height: 500px;">
                  </div>
               </div>
            </div>
         </div>
   
         <div class="flex flex-row justify-end mt-6">
            <button (click)="modal = 0; imageData = null;" [disabled]="working.saving" class="btn mr-2">
               <fa-icon icon=times></fa-icon>
               Cancelar
            </button>
            <button (click)="register_deposit()" [disabled]="working.saving" class="btn blue">
               <fa-icon icon=cash-register></fa-icon>
               {{ !working.saving? 'Registrar depósito' : 'Registrando...' }}
            </button>
         </div>
      </div>
   
      <!-- Ajuste extraordinario -->
      <div *ngIf="modal == 2" class="card">
         <h3 class="mb-6">Ajuste extraordinario a <span class="underline">{{ commerce_operation.name }}</span></h3>
   
         <div class="info-list w-full">
            <div class="row">
               <div class="label">Tipo de ajuste:</div>
               <div class="value">
                  <select [(ngModel)]="data.type" class="border border-gray-300 rounded py-1 mb-3 w-full">
                     <option [value]="0">Aumentar la deuda del comercio</option>
                     <option [value]="1">Restar de la deuda del comercio</option>
                  </select>
               </div>
            </div>
            <div class="row">
               <div class="label">Fecha:</div>
               <div class="value">
                  <input type=date [(ngModel)]="data.date_start" class="border border-gray-300 rounded mb-3">
               </div>
            </div>
            <div class="row">
               <div class="label">Monto del ajuste:</div>
               <div class="row">
                  <div class="w-full flex flex-row items-baseline">
                     <span class="mr-2">RD$ </span>
                     <input type=number [(ngModel)]="data.amount" class="flex-1 border border-gray-300 rounded text-right mb-3" placeholder="0.00">
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="label">Descripción:</div>
               <div class="row">
                  <input type=text [(ngModel)]="data.description" class="border border-gray-300 rounded w-full mb-3">
               </div>
            </div>
            <div class="row">
               <div class="label">Reference:</div>
               <div class="row">
                  <input type=text [(ngModel)]="data.reference" class="border border-gray-300 rounded w-full">
               </div>
            </div>
            <div class="row">
               <div class="label">Archivo adjunto:</div>
               <div class="row">
                  <div (click)="file.click()" class="border border-gray-300 rounded py-1 mt-4 w-full cursor-pointer text-center text-gray-700 hover:bg-blue-100">
                     <div *ngIf="!data.file_name">
                        <fa-icon icon=file-upload></fa-icon>
                        {{ !working.loading? 'Adjuntar archivo...' : 'Cargando...' }}
                     </div>
                     <div *ngIf="data.file_name">
                        <fa-icon [icon]="data.file_type.startsWith('image')? 'file-image' : data.file_type.endsWith('pdf')? 'file-pdf' : 'file'"></fa-icon>
                        {{ data.file_name.length > 25? (data.file_name.substr(0, 10).trim() + '...' + data.file_name.substr(data.file_name.length - 10, 10).trim()) : data.file_name }}
                     </div>
                  </div>
                  <input #file type=file accept="image/jpeg,image/png,application/pdf" (input)="capture_file($event)" class="border border-gray-300 rounded w-full hidden">
                  <div>
                     <img class="m-3" src={{imageData}} alt="" style="max-width: 500px; max-height: 500px;">
                  </div>
               </div>
            </div>
         </div>
   
         <div class="flex flex-row justify-end mt-6">
            <button (click)="modal = 0; imageData = null;" [disabled]="working.saving" class="btn mr-2">
               <fa-icon icon=times></fa-icon>
               Cancelar
            </button>
            <button (click)="register_adjustment()" [disabled]="working.saving" class="btn yellow">
               <fa-icon icon=wrench></fa-icon>
               {{ !working.saving? 'Ajustar' : 'Ajustando...' }}
            </button>
         </div>
      </div>
   
       <!--Enviar mensaje whatsapp  -->
       <div *ngIf="modal == 3" class="card">
         <h3 class="mb-6">Enviar balance a <span class="underline">{{ commerce_operation.name }}</span></h3>
         <div class="info-list w-full">
            <div class="row">
               <div class="label">Número de whatsapp:</div>
               <div class="row">
                  <input type=text [(ngModel)]="commerce_operation.representative_mobile_phone " class="border border-gray-300 rounded w-full">
               </div>
            </div>
         </div>
   
         <div class="flex flex-row justify-end mt-6">
            <button (click)="modal = 0" [disabled]="working.saving" class="btn mr-2">
               <fa-icon icon=times></fa-icon>
               Cancelar
            </button>
            <button (click)="send_message()" [disabled]="working.saving" class="btn bg-green-400 hover:bg-green-500">
               <fa-icon [icon]="['fab', 'whatsapp']"></fa-icon>
               Enviar Mensaje
               <!-- {{ !working.saving? 'Ajustar' : 'Ajustando...' }} -->
            </button>
            <button (click)="editContactCommerce()" [disabled]="working.saving" class="btn bg-blue-300 ml-2 hover:bg-blue-400" >
               Guardar número como predeterminado
               <!-- {{ !working.saving? 'Ajustar' : 'Ajustando...' }} -->
            </button>
         </div>
      </div>
      <div class="card" *ngIf="modal == 4">
       <div>
         <img src={{imageData}} alt="" style="max-width: 600px; max-height: 600px;">
       </div>
       <div class="flex flex-row justify-end mt-6">
            <div class="mr-3">
               <div (click)="file.click()" class="border border-gray-300 rounded py-1 w-full cursor-pointer text-center text-gray-700 hover:bg-blue-10">
                  <div *ngIf="!data.file_name">
                     <fa-icon icon=file-upload></fa-icon>
                     {{ !working.loading? 'Cambiar  archivo...' : 'Cargando...' }}
                  </div>
                  <div *ngIf="data.file_name">
                     <fa-icon [icon]="data.file_type.startsWith('image')? 'file-image' : data.file_type.endsWith('pdf')? 'file-pdf' : 'file'"></fa-icon>
                     {{ data.file_name.length > 25? (data.file_name.substr(0, 10).trim() + '...' + data.file_name.substr(data.file_name.length - 10, 10).trim()) : data.file_name }}
                  </div>
               </div>
               <input #file type=file accept="image/jpeg,image/png,application/pdf" (input)="capture_file($event)" class="border border-gray-300 rounded w-full hidden">
            </div>
   
            <div>
               <button (click)="change_deposit_image()" [disabled]="working.saving" class="btn mr-2">
                  Save
               </button>
            </div>
   
            <div>
               <button (click)="modal = 0; imageData = null;" [disabled]="working.saving" class="btn mr-2">
               <fa-icon icon=times></fa-icon>
                  Cancelar
               </button>
            </div>
       </div>
       </div>

       <div class="card" *ngIf="modal == 5">
         <h3 class="mb-3">Confirmar acción</h3>
         Está segur@ que desea eliminar el elemento {{selected_movement.description}} ?
         <div class="mt-3">
            <button type="button" class="btn mr-6" (click)="on_delete_movement()">Delete</button>
            <button type="button" class="btn" (click)="modal = 0;">Cancel</button>
         </div>
      </div>
   </div>



</div>
