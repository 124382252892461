<h2 class="mb-6">Transacciones por Contrato</h2>

<div class="card w-full">
   <!-- Búsqueda -->
   <div class="flex flex-row items-stretch">
      <input [(ngModel)]="contract_id" (keydown.enter)="contract_id? query() : null" placeholder="Número de Contrato" class="border rounded-l">
      <button (click)="query()" [disabled]="!contract_id || working" class="btn indigo !rounded-l-none">
         <fa-icon icon="search" class="text-sm"></fa-icon>
         {{ !working? 'Buscar' : 'Buscando...' }}
         <busy *ngIf="working"></busy>
      </button>
   </div>

   <!-- Resultados -->
   <div *ngIf=transactions class="mt-8">
      <table-control #transacciones for="transacciones" fem [source]="filtered_transactions" [filter]="filter_string"></table-control>
      <!-- Filtros -->
      <div class="flex flex-row items-baseline border-t border-b space-x-8">
         <div (click)="extended_table = !extended_table" class="cursor-pointer">
            <fa-icon [icon]="extended_table? 'toggle-on' : 'toggle-off'" class="align-middle text-lg {{ extended_table? 'text-indigo-800' : '' }}"></fa-icon>
            Tabla extendida
         </div>
         <div (click)="include_queries = !include_queries" class="cursor-pointer">
            <fa-icon [icon]="include_queries? 'toggle-on' : 'toggle-off'" class="align-middle text-lg {{ include_queries? 'text-indigo-800' : '' }}"></fa-icon>
            Incluir consultas
         </div>
         <div (click)="exclude_non_successes = !exclude_non_successes" class="cursor-pointer">
            <fa-icon [icon]="exclude_non_successes? 'toggle-on' : 'toggle-off'" class="align-middle text-lg {{ exclude_non_successes? 'text-indigo-800' : '' }}"></fa-icon>
            Mostrar solo OK
         </div>
         <div class="flex-1"></div>
         <input [(ngModel)]=filter_string placeholder="🔍 Filtrar..." class="border rounded mt-2 mb-2">
      </div>

      <div class="flex flex-row items-baseline border-t border-b space-x-8 mt-4 justify-between">
         <div class="flex flex-row my-3">
           <div class="mr-4">Modalidad:</div>
           <div class="mr-4">
             <input type="radio" [value]="0" name="type" id="type-0" [(ngModel)]="filter_type" class="mr-1 cursor-pointer align-middle">
             <label for="type-0" class="cursor-pointer align-middle">Todos</label>
           </div>
           <div class="mr-4">
             <input type="radio" [value]="1" name="type" id="type-1" [(ngModel)]="filter_type" class="mr-1 cursor-pointer align-middle">
             <label for="type-1" class="cursor-pointer align-middle">Pospago</label>
           </div>
           <div>
             <input type="radio" [value]="2" name="type" id="type-2" [(ngModel)]="filter_type" class="mr-1 cursor-pointer align-middle">
             <label for="type-2" class="cursor-pointer align-middle">Prepago</label>
           </div>
         </div>
       </div>

      <table-paginator class="mb-2 mt-2" for="transacciones"></table-paginator>
      <table class="my-2 {{ extended_table? 'text-sm' : '' }}">
         <thead>
            <th></th>
            <th>Tipo Tran.</th>
            <th>Cliente</th>
            <th colspan=3>
               <div class="min-w-max">Monto (RD$)</div>
            </th>
            <th>Facturador</th>
            <th>Modo Pago</th>
            <th *ngIf=extended_table>Doc. Pago</th>
            <th *ngIf=extended_table>Respuesta</th>
            <th>Fecha</th>
            <th>Punto de Venta</th>
            <th  *ngIf=extended_table>Lote</th>
            <th *ngIf=extended_table>Sec.</th>
            <th *ngIf=extended_table>Nro. Transacción</th>
         </thead>
         <tbody>
            <tr *ngFor="let transaction of transacciones.slice" class="{{ transaction.status.id === 5? 'bg-gray-200 hover:bg-gray-300' : 'hover:bg-gray-100' }}">
               <td class="text-center"
                  [class.bg-green-400]="transaction.status.id == 2"
                  [class.bg-yellow-400]="transaction.status.id === 4"
                  [class.bg-red-400]="transaction.status.id === 3"
                  [class.bg-gray-300]="transaction.status.id === 5">
                  <fa-icon [icon]="[2,5].includes(transaction.status.id)? 'thumbs-up' : transaction.status.id === 4? 'eraser' : transaction.status.id == 1? 'hourglass-half' : 'times-circle'"></fa-icon>
               </td>
               <td>{{ transaction.type?.description }}</td>
               <td>{{ transaction.client }}</td>
               <td class="w-0 pr-2">$</td>
               <td class="w-0 text-right">
                  <div class="min-w-max">{{ transaction.amount | currency:' ' }}</div> 
               </td>
               <td></td>
               <td>{{ transaction.invoicer?.description }}</td>
               <td>{{ transaction.payment_method?.description }}</td>
               <td *ngIf=extended_table>{{ transaction.payment_document }}</td>
               <td *ngIf=extended_table>{{ transaction.response.code }}</td>
               <td>{{ transaction.date | date:'dd/MMM/yyyy hh:mm aa':null:'es-DO' }}</td>
               <td>{{ transaction.commerce?.description }}</td>
               <td  [routerLink]="'/transactions/lot/' + (transaction.prepaid? 'prepaid' : 'postpaid') + '/' + transaction.lot"
                    class="cursor-pointer hover:text-indigo-800" *ngIf=extended_table>{{ transaction.lot }} </td>
               <td *ngIf=extended_table>{{ transaction.sequence }}</td>
               <td *ngIf=extended_table>{{ transaction.id }}</td>
                  <!-- <td class="w-0 !px-0">
                     <button *ngIf="[2,7].includes(transaction.type.id) && transaction.status.id === 2" class="btn !py-0 min-w-max">
                        Anular
                        <fa-icon icon=eraser></fa-icon>
                     </button>
                  </td> -->
            </tr>
         </tbody>
      </table>
      <table-paginator class="mt-2" for="transacciones"></table-paginator>
   </div>
</div>
