<h2 class="mb-6">Transacciones</h2>

<div class="card w-full">
   <!-- Búsqueda -->
   <div class="flex flex-row items-start">
      <div class="mr-8" style="flex-grow: 1;">
         <h4><label for=agencies>Agencia:</label></h4>
         <select id=agencies [(ngModel)]="selected_agency_id" (change)="handle_agency_change()" class="px-3 py-2 border rounded w-full" style="margin-top: 0.75rem">
            <option *ngIf="!selected_agency_id"></option>
            <option *ngFor="let agency of agencies" [value]=agency.id>{{ agency.description }}</option>
         </select>
         <h4 class="mt-6">Punto(s) de Venta:</h4>
         <multi-select #myMultiSelect class="border border-gray-200 rounded w-full max-w-screen-2xl z-0"
         [items]=agency_commerces [(selection)]="selected_commerces" [converter]=pos_name style="margin-top: 0.75rem"></multi-select>
      </div>
      <div class="mr-8">
         <h4 class="mb-3"><label for=date-start>Desde:</label></h4>
         <input id=date-start type=date [(ngModel)]="date_start" [max]="date_end" class="border rounded mb-3">
         <h4 class="mt-5 mb-2"><label for=date-end>Hasta:</label></h4>
         <input id=date-end type=date [(ngModel)]="date_end" [min]="date_start" [max]="today" class="border rounded">
      </div>
   </div>
   <div class="flex flex-row items-start mt-4 place-content-end max-w-full z-10">
    <button (click)="query()" [disabled]="working || !can_query" class="btn blue mr-4 max-w-[150px]">
       <fa-icon icon="search"></fa-icon>
       {{ !working? 'Buscar' : 'Buscando...' }}
       <busy *ngIf="working"></busy>
    </button>
    <button (click)="export_excel_detalle()" [disabled]="working || !transactions?.length" class="btn block max-w-[150px]">
       <fa-icon icon=file-excel></fa-icon>
       Exportar Detalle
    </button>
    <div class="ml-4">
      <button (click)="exportar_excel_total()" [disabled]="working || !transactions?.length" class="btn block max-w-[150px]">
         <fa-icon icon=file-csv></fa-icon>
         Exportar Total
      </button>
   </div>
 </div>
   <!-- Resultados -->
   <div *ngIf=transactions?.length>
      <app-transaction-table #transaction_table [transactions]="transactions"></app-transaction-table>
   </div>
</div>
