import { formatCurrency, formatDate } from '@angular/common';
import { Component, Inject,LOCALE_ID  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateContactCommerceRequest } from 'src/app/shared/models/api.models';
import { CommerceDetailed, CommerceMovement, CommerceOperationDetails, Invoicer, Item, PaymentMethod, Transaction } from 'src/app/shared/models/models';
import { DataService } from 'src/app/shared/services/data.service';
import { State } from 'src/app/shared/state.shared';
import { error_toast, success_toast } from 'src/app/utilities/toaster/toaster.utilities';
import { getDates, format_date_iso, sum, today as today_ } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-commerce-conciliation',
  templateUrl: './commerce-conciliation.component.html',
  styleUrls: []
})
export class CommerceConciliationComponent {
  working = {
    loading: false,
    saving: false
  };

  tab: number = 1;
  modal: number = 0;
  imageData: string;
  commerce: CommerceDetailed;
  invoicers: Invoicer[] = [];
  filtered_invoicers: Invoicer[] = [];
  commerce_operation: CommerceOperationDetails;
  movements: CommerceMovement[];
  commerceContact: UpdateContactCommerceRequest;
  transactions: Transaction[];
  deposits: CommerceMovement[] = [];
  ajustes: CommerceMovement[] = [];
  selected_movement: CommerceMovement = null;

  totals_per_day_and_invoicer: number[][];
  totals_per_invoicer: number[];
  totals_per_day: number[];
  total: number = 0;
  depositado: number = 0;
  ajuste: number = 0;
  balance: number = 0;

  today: string;
  date_start: string;
  date_end:   string;
  dates: String[] = [];

  get pending(): number {
    return sum(this.commerce_operation.pending_conciliation.map(p => p.pending));
  }

  data = {
    type: <0|1|2>0,
    date_start: <string|Date>null,
    date_end: <string|Date>null,
    amount: 0.00,
    description: <string>null,
    reference: <string>null,
    file_name: <string>null,
    file_type: <string>null,
    file_dataurl: <string>null
  }

  constructor(private api: DataService, active_route: ActivatedRoute, private router: Router,  @Inject(LOCALE_ID) public locale: string,) {
    active_route.params.subscribe(params => {
      api.commerce_operation(params.id, response => {
        if (response.succeeded) {
          this.commerce_operation = response.data;

            api.commerce_detailed(params.id, response => {
              if (response.succeeded) {
                  this.commerce = response.data;
                  for(const ag of this.commerce.agency_invoicers) {
                    this.invoicers.push({id: ag.invoicer.id, description: ag.invoicer.description + " POSPAGO"});
                    this.invoicers.push({id: ag.invoicer.id, description: ag.invoicer.description + " PREPAGO"});
                  }
                  this.query();      
              } else error_toast(response.error.message);
            });
          
        }
        else error_toast(response.error.message);
      });


      const today = today_();
      this.today = format_date_iso(today);
      this.date_end = format_date_iso(today);
      today.setDate(today.getDate() - 7);
      this.date_start = format_date_iso(today);
    

      api.commerce_movements(params.id, this.date_start, this.date_end, response => {
        if (response.succeeded) {
          this.update_movements(response);
        } else error_toast(response.error.message);
      });
    });
  }


  async query() {
    try {
      this.transactions = (await this.api.transactions_by_commerce(this.commerce_operation.id, this.date_start, this.date_end))
      .filter(
        t => t.response.code
        && t.response.code.endsWith('000') 
        && !t.response.code.includes('C')
      );
      
      // Remove unecessary invoicers
      this.filtered_invoicers = this.invoicers.filter(i => 
        i.description.includes("PREPAGO") ? 
          this.transactions.some(t => t.invoicer.id == i.id && t.prepaid == true) 
        : this.transactions.some(t => t.invoicer.id == i.id && t.prepaid == false)
      );

      this.dates = getDates(this.date_start, this.date_end);
      this.get_totals()

      this.api.commerce_movements(this.commerce_operation.id, this.date_start, this.date_end, response => {
        if (response.succeeded) {
          this.movements = response.data;
          this.update_movements(response);

        } else error_toast(response.error.message);
      });

    } catch (e) {
      if (e instanceof Error) {
         error_toast(e.message);
      }
   }
  }

  get_totals() {
    this.totals_per_day_and_invoicer = [];
    this.totals_per_invoicer = [];
    this.totals_per_day = [];

    // Calculate totals_per_day_and_invoicer and totals_per_invoicer
    for(const invoicer of this.filtered_invoicers) {
      let temp: number[] = []
      let total: number = 0;

      // POSPAGO
      for(const day of this.dates) {
        let total_amount = this.transactions.filter(
          t => t.invoicer.id == invoicer.id
          && (typeof t.date == "string" ? t.date.split("T")[0] == day : formatDate(t.date, 'yyyy-MM-dd', 'es-DO') == day)
          && t.prepaid == invoicer.description.includes("PREPAGO")
        )
        .reduce((total, transaction) => total + transaction.amount, 0);
                
        temp.push(total_amount);
        total += total_amount;
      }
      
      this.totals_per_day_and_invoicer.push(temp);
      this.totals_per_invoicer.push(total);
    }

    // Calculate totals_per_day
    for(let i = 0; i < this.dates.length; i++) { 
      let total: number = 0;
      for (let j = 0; j < this.filtered_invoicers.length; j++) {
        total += this.totals_per_day_and_invoicer[j][i];
      }

      this.totals_per_day.push(total);
    }

    // Calculate total
    this.total = this.totals_per_invoicer.reduce((total, amount) => total + amount, 0);

  }


  calculate_total_amount_per_invoicer_for_date(invoicer: Number, day: String, prepaid: number, include_cards: Boolean): number {
    const card_payment_methods = [3, 4, 5];

    return this.transactions.filter(
      t => t.invoicer.id == invoicer
      && (typeof t.date == "string" ? t.date.split("T")[0] == day : formatDate(t.date, 'yyyy-MM-dd', 'es-DO') == day)
      && (prepaid == -1 || prepaid == 1 ? t.prepaid == true : t.prepaid == false)
      && (t.payment_method.id == 1 || (include_cards && card_payment_methods.includes(t.payment_method.id)))
    )
    .reduce((total, transaction) => total + transaction.amount, 0);
  }

  update_movements(response: any) {
    this.movements = response.data;

    this.ajustes = this.movements.filter(m => m.description.includes('Ajuste'));
    this.ajuste = this.ajustes.reduce((total, movement) => total + movement.amount, 0);
    var filtered_movements = this.movements.filter(m => !m.description.includes('Ajuste'));
    
    this.deposits = filtered_movements.filter(m => m.description.includes('DEPOSITO'));
    this.depositado = -1 * this.deposits.reduce((total, movement) => total + movement.amount, 0);
    filtered_movements = filtered_movements.filter(m => m.amount >= 0);

    this.total = this.total ?? 0;
    this.balance = this.total - this.depositado + this.ajuste;
    
  }

  capture_file(event: Event) {
    const file = (<HTMLInputElement>event.target).files[0];
    if (file) {
       this.working.loading = true;
       const reader = new FileReader();
       reader.readAsDataURL(file);
       reader.onload = (evt) => {
          const dataurl = <string>evt.target.result;
          this.working.loading = false;
          this.data.file_name = file.name;
          this.data.file_type = file.type;
          this.data.file_dataurl = dataurl;
          this.imageData = dataurl;
       };
    }
  }

  handlePastedContent(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;

    if (clipboardData.files.length > 0) {
      const file = clipboardData.files[0];
      if (file.type.includes('image') || file.type.includes('pdf')) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (evt) => {
          const dataurl = <string>evt.target.result;
          this.data.file_name = file.name;
          this.data.file_type = file.type;
          this.data.file_dataurl = dataurl;
          this.imageData = dataurl;
        };
      }
    }
  }

  change_deposit_image() {
    this.working.loading = true;

    this.api.update_deposit_image(this.commerce_operation.id, this.selected_movement.sequence, {
      file_name: this.data.file_name,
      file_type: this.data.file_type,
      file_data: this.data.file_dataurl
    }, response => {
      this.working.saving = false;
      if (response.succeeded) {
        success_toast('Imagen depósito actualizada');

        this.api.commerce_movements(this.commerce_operation.id, this.date_start, this.date_end, response => {
          if (response.succeeded) {
            this.modal = 0;
            this.imageData = null;
            this.update_movements(response);
          } else error_toast(response.error.message);
        });

      } else error_toast(response.error.message);
    });

  }

  register_deposit() {
    this.working.saving = true;
    this.api.register_deposit(this.commerce_operation.id, {
      method: <0|1|2>Number.parseInt(<any>this.data.type),
      date_start: this.date_start,
      date_end: this.date_end,
      amount: this.data.amount, 
      reference: this.data.reference,
      file_name: this.data.file_name,
      file_type: this.data.file_type,
      file_data: this.data.file_dataurl
    }, response => {
      this.working.saving = false;
      if (response.succeeded) {
        success_toast('Depósito registrado');
        this.data = <any>{};
        this.api.commerce_movements(this.commerce_operation.id, this.date_start, this.date_end, response => {
          if (response.succeeded) {
            this.modal = 0;
            this.imageData = null;
            this.update_movements(response);
          } else error_toast(response.error.message);
        });
      }
      else error_toast(response.error.message);
    });
  }

  register_adjustment() {
    this.working.saving = true;
    this.api.register_adjustment(this.commerce_operation.id, {
      type: <0|1>Number.parseInt(<any>this.data.type),
      description: this.data.description,
      date: this.data.date_start,
      amount: this.data.amount,
      reference: this.data.reference,
      file_name: this.data.file_name,
      file_type: this.data.file_type,
      file_data: this.data.file_dataurl
    }, response => {
      this.working.saving = false;
      if (response.succeeded) {
        success_toast('Ajuste realizado');
        this.data = <any>{};
        this.api.commerce_movements(this.commerce_operation.id, this.date_start, this.date_end, response => {
          if (response.succeeded) {
            this.modal = 0;
            this.imageData = null;
            this.update_movements(response);
          } else error_toast(response.error.message);
        });
      }
      else error_toast(response.error.message);
    });
  }

  
  delete_movement(movement: CommerceMovement) {
    this.modal = 5;
    this.selected_movement = movement;
  }

  on_delete_movement() {
    if(!this.selected_movement) return;
    this.working.saving = true;

    this.api.delete_movement(this.commerce_operation.id, this.selected_movement.sequence, response => {
      this.working.saving = false;
      this.modal = 0;
      if (response.succeeded) {
        success_toast('Movimiento eliminado.');
        this.api.commerce_movements(this.commerce_operation.id, this.date_start, this.date_end, response => {
          if (response.succeeded) {
            this.update_movements(response);
          } else error_toast(response.error.message);
        });
      } else error_toast(response.error.message);
    });
  }


  send_message() {
    this.modal = 0;
    var urlWhatsapp = 'https://api.whatsapp.com/send/?phone=';
    var mobile = this.commerce_operation.representative_mobile_phone.replace(/-/g, "");
    var messageBalance = "Muy buenos días, su balance pendiente del *" 
    + this.date_start + "* al *" 
    + this.date_end +  "* es de *" 
    + formatCurrency(this.balance, this.locale, 'RD$')
    + '*. Por favor mandar a este número el comprobante de pago correspondiente a este período. De parte de Transneg, gracias por su colaboración.';
    // if (this.commerce_operation.name_intermediary.description === null) {
    //   const messageWhatsapp = messageBalance;
    //   urlWhatsapp = urlWhatsapp + mobile + '?text=' + messageWhatsapp;
    // } else {
    //   const messageWhatsapp = messageBalance;
    //   urlWhatsapp = urlWhatsapp + mobile + '?text=' + messageWhatsapp + ". Para mas informacion, haz click en este link: http://"
    //     + this.commerce_operation.name_intermediary.description + '.servicios.mobi'  + "/balance.aspx?id=" + this.commerce_operation.guid_pv
    // }

    urlWhatsapp = urlWhatsapp + mobile + '&text=' + messageBalance;

    window.open(urlWhatsapp, '_blank');
    return urlWhatsapp;
  }

  retrieve_resource(movement: CommerceMovement) {
    var resource_id = movement.resource.id;
    this.selected_movement = movement;
    this.api.retrieve_operation_resource(this.commerce_operation.id, resource_id, async response => {
      if (response.succeeded) {

        if(response.data.dataurl.includes('data:image/')){
          this.imageData = response.data.dataurl
          this.modal = 4
        }else{
          const pdf = await (await fetch(response.data.dataurl)).blob()
          const pdf_url = URL.createObjectURL(pdf)
          window.open(pdf_url)
        }
        // export_file(response.data.filename, response.data.dataurl);
      } else error_toast(response.error.message);
    });
  }

  permissDeposit() {
   return State.getUserPermiss('Realizar registro de deposito')
  }

  permissAjust() {
    return State.getUserPermiss('Realizar ajuste extraordinario')
  }

  editContactCommerce() {
    this.api.edit_contact_commerce(this.commerce_operation.id, { contact_mobile_phone: this.commerce_operation.representative_mobile_phone}, response => {
      this.working.saving = false;
      if (response.succeeded) {
        success_toast('Numero actualizado correctamente');
        this.commerceContact.contact_mobile_phone = this.commerce_operation.representative_mobile_phone;
      } else error_toast(response.error.message);
    })
  }

  back() {
    this.router.navigate(['commerces', 'conciliation'])
  }
}
