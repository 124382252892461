<h2 class="mb-6">Detalle de Lote {{ mode == 'postpaid'? 'Pospago' : 'Prepago' }}</h2>

<div class="card w-full">
   <div class="border-b pb-6 mb-6 ">
      <h2 class="mb-3">
         <fa-icon icon=store-alt></fa-icon>
         {{ lot.commerce.description }}
      </h2>

      <div class="flex flex-row justify-between">
         <div class="text-lg">
            <span class="font-medium">Lote: </span>
            {{ lot.id }}
         </div>
         <div class="text-lg">
            <span class="font-medium">Fecha inicio: </span>
            {{ lot.date_opened | date:'dd/MMM/yyyy hh:mm aa':null:'es-DO' }}
         </div>
         <div *ngIf=lot.date_closed class="text-lg">
            <span class="font-medium">Fecha cierre: </span>
            {{ lot.date_closed | date:'dd/MMM/yyyy hh:mm aa':null:'es-DO' }}
         </div>
         <div *ngIf=!lot.date_closed></div>
         <button (click)=back() class="btn">
            <fa-icon icon="arrow-left"></fa-icon>
            Volver
         </button>
      </div>
   </div>

   <div class="border-b pb-6 mb-6">
      <div class="flex flex-row"> 
         <div class="mr-10">
            <span class="font-medium">Total General: </span>
            <span class="w-0">RD$ </span>
            <span class="text-right">{{ lot.total | currency:' ' }}</span>
         </div>
         <div class="mr-10">
            <span class="font-medium">Anulaciones: </span>
            <span class="w-0">RD$ </span>
            <span class="text-right">{{ lot.total_nullified | currency:' ' }}</span>
         </div>
         <div class="flex flex-row-reverse flex-1">
            <button (click)="print()" class="btn blue" title="Imprimir lote">
               <fa-icon icon=print></fa-icon>
            </button>
         </div>
      </div>
      <div>
        <div>
          <h3 class="border-b my-4">
            <fa-icon icon="info-circle"></fa-icon>
            Montos por Facturador
          </h3>
            <div class="flex" style="display: flex; flex-direction: row; flex-wrap: wrap;">
              <div class="mr-4 flex" *ngFor="let item of transaction_groups">
                <span class="font-medium">
                   {{item.payment_method}}:
                </span>
                <span class="ml-2" > {{item.amount | currency:' ' }} </span>
              </div>
          </div>
       </div>
      </div>
      <!-- <table class="mt-4">
        <thead>
           <th>Facturador</th>
           <th>No. Transaccion</th>
           <th colspan=3>Monto (RD$)</th>
        </thead>
        <tbody>
           <tr *ngFor="let item of this.transaction_groups">
              <td>{{ item.payment_method }}</td>
              <td>{{ item.transactions.length }}</td>
              <td class="w-0">$</td>
              <td class="text-right">{{ item.amount | currency:' ' }}</td>
              <td></td>
           </tr>
        </tbody>
     </table> -->
   </div>

   <!-- Detalle -->
   <!-- <div class="w-full justify-items-end">
    <input type="text" [(ngModel)]=filter_string placeholder="🔍 Filtrar..." class="border rounded w-80 mb-6">
   </div> -->
   <div>
      <div *ngFor="let group of transaction_groups" class="mb-10 last:mb-0">
         <h3 class="mb-4">{{ group.payment_method }}</h3>

         <table-control #trans for="transacciones en {{ group.payment_method | lowercase }}" [source]=group.transactions></table-control>
         <table>
            <thead>
               <th>Facturador</th>
               <th>Secuencia</th>
               <th>Núm. Recibo</th>
               <th>Cliente</th>
               <th colspan=3>Monto (RD$)</th>
               <th>Fecha</th>
            </thead>
            <tbody>
               <tr *ngFor="let transaction of trans.slice">
                  <td>{{ transaction.invoicer?.description }}</td>
                  <td>{{ transaction.sequence }}</td>
                  <td>{{ transaction.id }}</td>
                  <td>{{ transaction.client }}</td>
                  <td class="w-0">$</td>
                  <td class="text-right">{{ transaction.amount | currency:' ' }}</td>
                  <td></td>
                  <td>{{ transaction.date | date:'dd/MMM/yyyy hh:mm:ss aa':null:'es-DO' }}</td>
               </tr>
            </tbody>
         </table>
         <table-paginator class="mt-2" for="transacciones en {{ group.payment_method | lowercase }}"></table-paginator>
      </div>
   </div>
</div>
