import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsDo from '@angular/common/locales/es-DO';

import { TransactionsRoutingModule } from './transactions-routing.module';
import { ContractViewComponent } from './contract-view/contract-view.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { PointsOfSaleViewComponent } from './points-of-sale-view/points-of-sale-view.component';
import { PointOfSaleComponent } from './points-of-sale-view/point-of-sale/point-of-sale.component';
import { OpenLotsComponent } from './open-lots/open-lots.component';
import { ClosedLotsComponent } from './closed-lots/closed-lots.component';
import { LotComponent } from './lot/lot.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { UtilitiesModule } from '../utilities/utilities.module';
import { SharedModule } from '../shared/shared.module';
import { LotPrintComponent } from './lot-print/lot-print.component';
import { TransactionTableComponent } from './transaction-table/transaction-table.component';


registerLocaleData(localeEs, 'es');
registerLocaleData(localeEsDo, 'es-DO');

@NgModule({
  declarations: [
    ContractViewComponent,
    PointsOfSaleViewComponent,
    PointOfSaleComponent,
    OpenLotsComponent,
    ClosedLotsComponent,
    LotComponent,
    TransactionsComponent,
    LotPrintComponent,
    TransactionTableComponent,
  ],
  imports: [
    CommonModule,
    TransactionsRoutingModule,
    FontAwesomeModule,
    FormsModule,
    UtilitiesModule,
    SharedModule
  ],
})
export class TransactionsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab);
  }
}
