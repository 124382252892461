<h2>{{ lot.commerce.description }}</h2>

<h3 class="my-4">Lote {{ lot.prepaid? 'Prepago' : 'Pospago' }} {{ lot.id }}</h3>

<table class="my-4 w-max">
   <tbody>
      <tr>
         <td class="border-0 font-medium">Inicio:</td>
         <td class="border-0">{{ lot.date_opened | date:'dd/MMM/yyyy hh:mm aa':null:'es-DO' }}</td>
      </tr>
      <tr>
         <td class="border-0 font-medium">Final:</td>
         <td class="border-0">{{ lot.date_closed? (lot.date_opened | date:'dd/MMM/yyyy hh:mm aa':null:'es-DO') : '(Lote Abierto)' }}</td>
      </tr>
      <tr *ngFor="let method of payment_methods">
         <td class="border-0 font-medium">Total en {{ titlecase(method.description) }}:</td>
         <td class="border-0">{{ total(method.id) | currency:'RD$ ' }}</td>
      </tr>
      <tr>
         <td class="border-0 font-medium">Total anulado:</td>
         <td class="border-0">{{ lot.total_nullified | currency:'RD$ ' }}</td>
      </tr>
      <tr>
         <td class="border-0 font-medium">Total general:</td>
         <td class="border-0">{{ lot.total | currency:'RD$ ' }}</td>
      </tr>
   </tbody>
</table>

<table class="my-4">
   <thead>
      <th class="py-1 text-lg font-medium">Facturador</th>
      <th class="py-1 text-lg font-medium">Secuencia</th>
      <th class="py-1 text-lg font-medium">Núm. Recibo</th>
      <th class="py-1 text-lg font-medium">Cliente</th>
      <th class="py-1 text-lg font-medium" colspan="3">Monto (RD$)</th>
      <th class="py-1 text-lg font-medium">Fecha</th>
   </thead>
   <tbody *ngFor="let method of payment_methods">
      <tr><td colspan="8" class="font-medium">{{ titlecase(method.description) }}</td></tr>
      <tr *ngFor="let transaction of transactions(method.id)">
         <td>{{ transaction.invoicer.description }}</td>
         <td class="border-l">{{ transaction.sequence }}</td>
         <td class="border-l">{{ transaction.id }}</td>
         <td class="border-l">{{ transaction.client }}</td>
         <td class="border-l w-0">$</td>
         <td class="text-right w-0">{{ transaction.amount | currency:' ' }}</td>
         <td></td>
         <td class="border-l">{{ transaction.date | date:'dd/MMM/yyyy hh:mm aa':null:'es-DO' }}</td>
      </tr>
      <tr><td colspan="8" class="text-center">---Fin de transacciones en {{ method.description | lowercase }}---</td></tr>
   </tbody>
   <tbody>
      <tr><td colspan="8" class="font-medium">Anulaciones</td></tr>
      <tr *ngFor="let transaction of nullifications">
         <td>{{ transaction.invoicer.description }}</td>
         <td class="border-l">{{ transaction.sequence }}</td>
         <td class="border-l">{{ transaction.id }}</td>
         <td class="border-l">{{ transaction.client }}</td>
         <td class="border-l w-0">$</td>
         <td class="text-right w-0">{{ transaction.amount | currency:' ' }}</td>
         <td></td>
         <td class="border-l">{{ transaction.date | date:'dd/MMM/yyyy hh:mm aa':null:'es-DO' }}</td>
      </tr>
      <tr><td colspan="8" class="text-center">---Fin de anulaciones---</td></tr>
   </tbody>
</table>

<div class="my-4 text-center">=== Fin de detalle de lote ===</div>