import { Component, Input } from "@angular/core";
import { TableControl } from "./table-control.component";

@Component({
   selector: 'table-paginator',
   template: `
      <div class="flex flex-col-reverse sm:flex-row items-baseline">
         <div *ngIf="show_text" style="flex-grow: 1;">
            <span *ngIf="pages">{{ label(for) }} {{ index_start + 1 }}–{{ index_end }} de {{ length }}</span>
            <span *ngIf="!pages">{{ length }} {{ for }}</span>
         </div>
         <div *ngIf="pages" class="flex flex-row items-stretch space-x-1px">
            <!-- First page -->
            <button *ngIf="ends" (click)="current_page = 0" style="min-width: {{ (1.75) + (size - 1) * .25 }}rem; background: {{ normal_color }}" class="border rounded focus:outline-none">
               <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-double-left" class="svg-inline--fa fa-angle-double-left fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path></svg>
               <!-- <fa-icon icon="angle-double-left"></fa-icon> -->
            </button>
            <!-- Previous page -->
            <button (click)="current_page = (current_page - 1)" style="min-width: {{ (1.75) + (size - 1) * .25 }}rem; background: {{ normal_color }}" class="border rounded focus:outline-none">
               <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg>
               <!-- <fa-icon icon="angle-left"></fa-icon> -->
            </button>
            <!-- Page numbers -->
            <div *ngFor="let page_num of bar" class="flex flex-col items-stretch border rounded" style="width: min-content">
               <button *ngIf="page_num !== current_page" (click)="current_page = (page_num)"
                  class="font-semibold focus:outline-none" style="min-width: {{ (1.75) + (size - 1) * .25 }}rem; padding: {{ 1 + (size - 1) * 2 }}px 0;{{ 'background: ' + (page_num === current_page? active_color : normal_color) + ';' }}">
                  {{ page_num + 1 }}
               </button>
               <input *ngIf="page_num === current_page" #page_input type="number" [value]="current_page + 1"
                  style="padding: {{ 1 + (size - 1) * 2 }}px 0; min-width: {{ (1.75) + (size - 1) * .25 }}rem; width: 100%; text-align: center; border: none; {{ 'background: ' + (page_num === current_page? active_color : normal_color) + ';' }}" class="font-semibold rounded"
                  (keydown.enter)="current_page = num(page_input.value) - 1" (focusout)="current_page = num(page_input.value) - 1">
            </div>
            <!-- Next page -->
            <button (click)="current_page = (current_page + 1)" style="min-width: {{ (1.75) + (size - 1) * .25 }}rem; background: {{ normal_color }}" class="border rounded focus:outline-none">
               <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>
               <!-- <fa-icon icon="angle-right"></fa-icon> -->
            </button>
            <!-- Last page -->
            <button *ngIf="ends" (click)="current_page = pages - 1" style="min-width: {{ (1.75) + (size - 1) * .25 }}rem; background: {{ normal_color }}" class="border rounded focus:outline-none">
               <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-double-right" class="svg-inline--fa fa-angle-double-right fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z"></path></svg>
               <!-- <fa-icon icon="angle-double-right"></fa-icon> -->
            </button>
         </div>
      </div>
   `
   , styles: [
      '* { color: rgba(0, 0, 0, .75) }'
      ,` .flex { display: flex; }
         .flex-row { flex-direction: row; }
         .flex-col { flex-direction: col; }
         .flex-col-reverse { flex-direction: col-reverse; }
         .items-baseline { align-items: baseline; }
         .items-stretch { align-items: stretch; }
         .space-x-1px > * + * { margin-left: 1px; }
         .border { border: 1px solid rgba(0, 0, 0, .1) }
         .rounded { border-radius: .35rem; }
         .font-semibold { font-weight: 600; }
         .focus:outline-none:focus { outline: none; }
         svg { max-height: 15px; }
         button { border-width: 0 !important; }
         input { margin: 0 !important; }

         /* Chrome, Safari, Edge, Opera */
         input::-webkit-outer-spin-button,
         input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
         }

         /* Firefox */
         input[type=number] {
            -moz-appearance: textfield;
         }
      `
      ,'@media(min-width: 640px) { .sm:flex-row { flex-direction: row; } }'
   ]
   , host: { style: 'display: block;' }
})
export class TablePaginatorComponent {
   private _for: string = "registros";
   private _current_page: number = 0;
   private _span: number = 0;
   private _length: number = 0;
   
   show_text = true;
   max_number_of_pages_in_bar: number = 5;
   normal_color: string = '#FFF';
   active_color: string = '#93C5FD';
   ends = true;

   get span(): number { return this._span; }
   set span(value: number) {
      this._span = value;
      this.current_page = this.current_page;
   }

   get length(): number { return this._length; }
   set length(value: number) {
      this._length = value;
      this.current_page = this.current_page;
   }

   get pages(): number {
      if (this.span == 0)
         return 0;
      return Math.ceil(this.length / this.span);
   }

   get for(): string { return this._for; }
   @Input() set for(value) {
      this._for = value;
      TableControl.when_span_is_set  (this.for, (span,   name) => { if (name === this.for) this.span   = span; });
      TableControl.when_length_is_set(this.for, (length, name) => { if (name === this.for) this.length = length; });
      TableControl.when_page_is_set  (this.for, (page, name)   => { if (name === this.for) this._current_page = page });
   }
   
   @Input() set notext(_: boolean | string) {
      if (_ === '')
         _ = true;
      this.show_text = !_;
   }
   @Input() set show(value: number) { this.max_number_of_pages_in_bar = Number.parseInt(<any>value); }
   @Input() set color(value: string) { this.normal_color = value; }
   @Input() set activecolor(value: string) { this.active_color = value; }
   @Input() set noends(_: string) { this.ends = false; }
   @Input() size = 1;

   get current_page(): number { return this._current_page; }
   set current_page(value: number) {
      if (value >= this.pages)
         this._current_page = this.pages - 1;
      else
         this._current_page = value;
      if (this.current_page < 0)
         this._current_page = 0;
      TableControl.set_page(this.current_page, this.for);
   }

   get index_start(): number { return this.current_page * this.span; }
   get index_end(): number { return Math.min((this.current_page + 1) * this.span, this.length) }

   get bar(): number[] {
      const npb = Math.min(this.max_number_of_pages_in_bar, this.pages);
      const half_npb = Math.ceil(npb * .5);
      if (this.current_page < half_npb)
         return range(0, npb)
      else if (this.current_page < this.pages - half_npb)
         return range(this.current_page + 1 - half_npb, this.current_page + half_npb);
      else
         return range(this.pages - npb, this.pages);
   }

   constructor() {
      TableControl.when_span_is_set  (this.for, (span, name)   => { if (name === this.for) this.span   = span; });
      TableControl.when_length_is_set(this.for, (length, name) => { if (name === this.for) this.length = length; });
      TableControl.when_page_is_set(this.for, (page, name)   => { if (name === this.for) this._current_page = page });
   }

   num(v: string | number): number {
      return Number.parseInt(<any>v);
   }

   label = titlecase;
}

function range(a: number, b?: number) {
   const output = [];
   if (b)
      for (let i = a; i < b; i++)
         output.push(i);
   else
      for (let i = 0; i < a; i++)
         output.push(i);
   return output;
}

function titlecase(value: string, language: 'es' | 'en' = 'es'): string {
   value = (value ?? '').toLowerCase().split(' ')
      .map(w => w?.length? (w[0].toUpperCase() + w.substr(1)) : '')
      .join(' ')
      .replace('De ', 'de ');
   switch (language) {
      case 'es':
         return value
            .replace(' De ', ' de ')
            .replace(' En ', ' en ');
      case 'en':
         return value.replace(' Of ', ' of');
   }
}