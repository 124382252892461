<h2 class="mb-6">Lotes abiertos</h2>

<busy *ngIf="working" class="block mx-auto w-max" [size]=80 [thickness]=.15 color=black></busy>

<div *ngIf="lots?.length" class="card w-full">
   <div class="flex flex-row items-baseline">
      <table-control #lotes for="lotes activos" [source]=filtered_lots [filter]=filter_string style="flex-grow: 1;"></table-control>
      <input type=text [(ngModel)]=filter_string placeholder="🔍 Filtrar..." class="border rounded w-72">
   </div>

   <div class="flex flex-row my-4">
      <div class="mr-4">Modalidad:</div>
      <div class="mr-4">
         <input type="radio" [value]="0" name="type" id="type-0" [(ngModel)]="filter_type" class="mr-1 cursor-pointer align-middle">
         <label for="type-0" class="cursor-pointer align-middle">Todos</label>
      </div>
      <div class="mr-4">
         <input type="radio" [value]="1" name="type" id="type-1" [(ngModel)]="filter_type" class="mr-1 cursor-pointer align-middle">
         <label for="type-1" class="cursor-pointer align-middle">Pospago</label>
      </div>
      <div>
         <input type="radio" [value]="2" name="type" id="type-2" [(ngModel)]="filter_type" class="mr-1 cursor-pointer align-middle">
         <label for="type-2" class="cursor-pointer align-middle">Prepago</label>
      </div>
   </div>

   <table class="text-sm ">
      <thead>
         <th>Modalidad</th>
         <th>Lote</th>
         <th>Punto de Venta</th>
         <th>Cant. Ef.</th>
         <th colspan="3">Monto Ef. (RD$)</th>
         <th>Cant. T.C</th>
         <th colspan="3">Monto T.C (RD$)</th>
         <th>Fecha inicio</th>
      </thead>
      <tbody>
         <tr *ngFor="let lot of lotes.slice" class="hover:bg-gray-100">
            <td>{{ lot.prepaid? 'Pre' : 'Pos' }}pago</td>
            <td class="text-indigo-700">
               <a [routerLink]="'/transactions/lot/' + (lot.prepaid? 'prepaid' : 'postpaid') + '/' + lot.id" class="cursor-pointer hover:text-indigo-800">{{ lot.id }}</a>
            </td>
            <td (click)=go(lot.commerce?.id)
            class="cursor-pointer hover:text-indigo-600">{{ lot.commerce?.description }}</td>
            <td class="min-w-max">{{ lot.details[0].number_payments}}</td>
            <td class="w-0">$</td>
            <td class="text-right w-0">
              <div class="min-w-max">{{ lot.details[0].amount_payments | currency:' '}}</div>
            </td>
            <td></td>
            <td>{{ lot.details[1].number_payments}}</td>
            <td class="w-0">$</td>
            <td class="text-right w-0">
              <div class="min-w-max">{{ lot.details[1].amount_payments | currency:' '}}</div>
            </td>
            <td></td>
            <td>{{ lot.date_opened | date:'dd/MMM/yyyy hh:mm aa':null:'es-DO' }}</td>
         </tr>
      </tbody>
   </table>
   <table-paginator class="mt-2" for="lotes activos"></table-paginator>
</div>
