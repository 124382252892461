<h2 class="mb-6">Lotes cerrados</h2>

<div class="card w-full">
  <div class="flex flex-row mb-6">
    <div class="mr-8">
       <div>Agencia:</div>
       <select [(ngModel)]="selected_agency_id" (change)="handle_agency_selection()" class="min-w-[300px] border rounded py-1 px-2 border-gray-400">
          <option *ngIf="!selected_agency_id"></option>
          <option *ngFor="let agency of agencies" [value]="agency.id">{{ agency.description }}</option>
       </select>
    </div>
    <div>
       <div>Punto de Venta / Cobrador:</div>
       <select [(ngModel)]="selected_commerce_id"  class="min-w-[300px] border rounded py-1 px-2 border-gray-400">
          <option *ngIf="!selected_commerce_id"></option>
          <option *ngFor="let commerce of agency_commerces" [value]="commerce.id">{{ commerce.name }}</option>
       </select>
    </div>
 </div>
   <div class="flex flex-row items-end">
      <div class="mr-6">
         <h4><label for=date-start>Desde:</label></h4>
         <input id=date-start type=date [(ngModel)]=date_start [max]=today class="border rounded">
      </div>
      <div class="mr-6">
         <h4><label for=date-end>Hasta:</label></h4>
         <input id=date-end type=date [(ngModel)]=date_end [max]=today class="border rounded">
      </div>
      <button (click)="query()" [disabled]="working" class="btn blue">
         <fa-icon icon="search"></fa-icon>
         {{ !working? 'Buscar' : 'Buscando...' }}
         <busy *ngIf="working"></busy>
      </button>
      <button (click)="reset()" class="btn bg-red-300 ml-4 hover:bg-red-400" >
        <fa-icon icon="eraser"></fa-icon>
          reiniciar
     </button>
   </div>
   <div class="box-content  w-[50%] p-4 border-2 note">
      <h4 class="mb-2">Nota:</h4>
      <p><span class="mr-2">➤</span>   Si desea consultar una fecha igual o menor a un mes, seleccione una Agencia</p>
      <p><span class="mr-2">➤</span>  Si desea consultar una fecha igual o mayor a un mes, seleccione un Punto de Venta</p>
  </div>
   <!-- Resultados -->
   <div *ngIf=lots class="mt-6">
      <div class="flex flex-row items-baseline">
         <table-control #lotes for="lotes" [source]=filtered_lots [filter]=filter_string style="flex-grow: 1;"></table-control>
         <input type=text [(ngModel)]=filter_string placeholder="🔍 Filtrar..." class="border rounded w-72">
      </div>

      <div class="flex flex-row my-3">
         <div class="mr-4">Modalidad:</div>
         <div class="mr-4">
            <input type="radio" [value]="0" name="type" id="type-0" [(ngModel)]="filter_type" class="mr-1 cursor-pointer align-middle">
            <label for="type-0" class="cursor-pointer align-middle">Todos</label>
         </div>
         <div class="mr-4">
            <input type="radio" [value]="1" name="type" id="type-1" [(ngModel)]="filter_type" class="mr-1 cursor-pointer align-middle">
            <label for="type-1" class="cursor-pointer align-middle">Pospago</label>
         </div>
         <div>
            <input type="radio" [value]="2" name="type" id="type-2" [(ngModel)]="filter_type" class="mr-1 cursor-pointer align-middle">
            <label for="type-2" class="cursor-pointer align-middle">Prepago</label>
         </div>
      </div>

      <table class="text-sm ">
        <thead>
           <th>Modalidad</th>
           <th>Lote</th>
           <th>Punto de Venta</th>
           <th>Cant. Ef.</th>
           <th colspan="3">Monto Ef. (RD$)</th>
           <th>Cant. T.C</th>
           <th colspan="3">Monto T.C (RD$)</th>
           <th>Fecha cierre</th>
        </thead>
        <tbody>
           <tr *ngFor="let lot of lotes.slice" class="hover:bg-gray-100">
              <td>{{ lot.prepaid? 'Pre' : 'Pos' }}pago</td>
              <td class="text-indigo-700">
                 <a [routerLink]="'/transactions/lot/' + (lot.prepaid? 'prepaid' : 'postpaid') + '/' + lot.id" class="cursor-pointer hover:text-indigo-800">{{ lot.id }}</a>
              </td>
              <td (click)=go(lot.commerce?.id)
              class="cursor-pointer hover:text-indigo-600">{{ lot.commerce?.description }}</td>
              <td class="min-w-max">{{ lot.details[0].number_payments}}</td>
              <td class="w-0">$</td>
              <td class="text-right w-0">
                <div class="min-w-max">{{ lot.details[0].amount_payments | currency:' '}}</div>
              </td>
              <td></td>
              <td>{{ lot.details[1].number_payments}}</td>
              <td class="w-0">$</td>
              <td class="text-right w-0">
                <div class="min-w-max">{{ lot.details[1].amount_payments | currency:' '}}</div>
              </td>
              <td></td>
              <td>{{ lot.date_closed | date:'dd/MMM/yyyy hh:mm aa':null:'es-DO' }}</td>
           </tr>
        </tbody>
     </table>
      <table-paginator class="mt-2" for="lotes"></table-paginator>
   </div>
</div>
