<h2 class="mb-6">Punto de Venta</h2>

<div class="card w-full">
   <div class="border-b pb-4 mb-8 flex flex-row items-baseline">
      <h2 class="flex flex-row items-center flex-1">
         <fa-icon icon="store-alt" class="text-xl mr-2"></fa-icon>
         {{ commerce.name }}
      </h2>
      <button (click)=back() class="btn">
         <fa-icon icon="arrow-left"></fa-icon>
         Volver
      </button>
   </div>

   <div>
      <!-- Datos Generales -->
      <div>
         <h3 class="border-b my-4">
            <fa-icon icon="info-circle"></fa-icon>
            Datos Generales
         </h3>
         <table>
            <tr>
               <td class="label">
                  <div>Código de comercio:</div>
               </td>
               <td class="value">{{ commerce.code }}</td>
               <td class="label">
                  <div>Nombre del contacto:</div>
               </td>
               <td class="value">{{ commerce.contact_name }}</td>
            </tr>
            <tr>
               <td class="label">
                  <div>Dirección:</div>
               </td>
               <td class="value">{{ commerce.address }}</td>
               <td class="label">
                  <div>Teléfono del contacto:</div>
               </td>
               <td class="value">{{ commerce.contact_phone }}</td>
            </tr>
            <tr>
               <td class="label">
                  <div>Tipo de comercio:</div>
               </td>
               <td class="value">{{ commerce.type === 'FI'? 'Físico' : 'Virtual' }}</td>
               <td class="label">
                  <div>Teléfono móvil del contacto:</div>
               </td>
               <td class="value">{{ commerce.contact_mobile_phone }}</td>
            </tr>
            <tr>
              <td class="label">
                 <div>Activo:</div>
              </td>
              <td class="value">
                <input type="checkbox" checked *ngIf="commerce?.enabled; else enabledFalse" (click)='change_enabled(!commerce?.enabled)'>
                <ng-template #enabledFalse>
                  <input type="checkbox" (click)='change_enabled(!commerce?.enabled)'>
                </ng-template>
              </td>
              <td class="label">
               <div>Agencia:</div>
            </td>
            <td class="value">{{ commerce.agency.description }}</td>
           </tr>
         </table>
      </div>
      <!-- Facturadores y métodos de pago -->
      <div>
         <h3 class="border-b my-8">
            <fa-icon icon="handshake"></fa-icon>
            Facturadores y métodos de pago
         </h3>

         <div class="flex flex-row flex-wrap">
            <div *ngFor="let agency_invoicer of commerce.agency_invoicers" class="mb-6 mr-8">
               <h3>{{ agency_invoicer.invoicer.description }}</h3>
               <div>
                  <div *ngFor="let method of agency_invoicer.payment_methods" class="text-indigo-800">- {{ method.description }}</div>
               </div>
            </div>
         </div>
      </div>
      <!-- Transacciones -->
      <div>
         <h3 class="border-b mt-4 mb-8">
            <fa-icon icon="file-invoice-dollar"></fa-icon>
            Transacciones
         </h3>

         <!-- Búsqueda de transacciones -->
         <div class="flex flex-row items-end">
            <div class="mr-4">
               <h4><label for=date-start>Desde:</label></h4>
               <input type="date" id=date-start [(ngModel)]=start_date [max]=today class="border rounded">
            </div>
            <div class="mr-4">
               <h4><label for=date-end>Hasta:</label></h4>
               <input type="date" id=date-end [(ngModel)]=end_date [max]=today class="border rounded">
            </div>
            <div>
               <button (click)="query_transactions()" [disabled]="working" class="btn blue">
                  {{ !working? 'Consultar' : 'Consultado...' }}
                  <fa-icon icon=file-invoice-dollar *ngIf="!working"></fa-icon>
                  <busy *ngIf="working"></busy>
               </button>
            </div>
            <div class="ml-4">
               <button (click)="exportar_excel()" [disabled]="working" class="btn block max-w-[150px]">
                  <fa-icon icon=file-excel></fa-icon>
                  Exportar Detalle
               </button>
            </div>
            <div class="ml-4">
               <button (click)="exportar_csv_total()" [disabled]="working" class="btn block max-w-[150px]">
                  <fa-icon icon=file-csv></fa-icon>
                  Exportar Total
               </button>
            </div>
         </div>

         <!-- Listado de transacciones -->
         <div *ngIf=transactions class="mt-6">
            <app-transaction-table [transactions]="transactions"  (filterTransations)="GetFilter($event)" (isExtendedTable)="toogleExtendedTable($event)"></app-transaction-table>
         </div>
      </div>
   </div>
</div>
