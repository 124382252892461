<h2 class="mb-6">Puntos de Venta</h2>

<busy *ngIf="working" class="block w-max mx-auto" [size]=80 [thickness]=.15 color=black></busy>

<div *ngIf="commerces?.length" class="card w-full">
   <div class="flex flex-row flex-wrap items-baseline">
      <table-control #puntos for="puntos de venta" [source]=commerces [filter]="filter" style="flex-grow: 1"></table-control>
      <input type=text [(ngModel)]="filter" placeholder="🔍 Filtrar..." class="border rounded w-72 mb-2">
   </div>
   <table>
      <thead>
         <th class="w-0"></th>
         <th>Tipo</th>
         <th>Nombre</th>
         <th>Localización</th>
         <th>Contacto</th>
      </thead>
      <tbody>
         <tr *ngFor="let commerce of puntos.slice" class="hover:bg-blue-100">
            <td>
               <button (click)=go(commerce.id) class="btn small" [ngClass]="commerce.enabled === true ? 'blue': 'inactive'" >
                  <fa-icon icon="store-alt"></fa-icon>
               </button>
            </td>
            <td>{{ commerce.type == 'FI'? 'Físico' : 'Virtual' }}</td>
            <td>
               <span (click)=go(commerce.id) class="cursor-pointer hover:text-indigo-600">{{ commerce.name }}</span>
            </td>
            <td>{{ commerce.address }}</td>
            <td>
               <div>{{ commerce.contact_name }}</div>
               <div>{{ commerce.contact_phone }}</div>
            </td>
         </tr>
      </tbody>
   </table>
   <table-paginator class="mt-2" for="puntos de venta"></table-paginator>
</div>
