import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from './shared/services/data.service';
import { State } from './shared/state.shared';
import { error_toast } from './utilities/toaster/toaster.utilities';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  constructor(private router: Router, api: DataService) {
    api.authenticate(response => {
      if (response.succeeded) {
        State.user = response.data;
      } else{
        error_toast(response.error.message);
        this.router.navigateByUrl('/login');
      }
    });
  }
}
