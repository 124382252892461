import { AfterViewChecked, Component } from '@angular/core';
import { Router } from '@angular/router';
import { LotDetailed, OrderDiscount, PaymentMethod, Transaction } from 'src/app/shared/models/models';
import { State } from 'src/app/shared/state.shared';
import { sum, titlecase, today, distinct } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-discount-print',
  templateUrl: './discount-print.component.html',
  styles: [
    `.itemsBox{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 14px;
      }
  `]
})
export class DiscountPrintComponent {
  discount: OrderDiscount = State.discount_to_print;
  logo =''
  titlecase = titlecase;
  fecha = today();
  constructor(private router: Router) {
    this.logo = State.getLogo(this.discount.order.invoicer.description);
    if (!this.discount)
      router.navigate([''])
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      window.print();
      window.history.back();
   }, 1000);

  }
}
