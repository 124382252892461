<div #container class="relative w-full">
   <div class="min-w-full overflow-x-hidden flex flex-row flex-wrap items-baseline px-2">

      <div *ngFor="let item of selected" class="rounded bg-blue-400 py-px my-px pr-2 mr-2 text-white flex flex-row items-baseline">
         <div (click)=remove(item) class="rounded px-2 cursor-pointer text-white hover:bg-blue-500 hover:text-gray-100 mr-1">
            <fa-icon icon="times" class="text-sm"></fa-icon>
         </div>
         {{ converter? converter(item) : item }}
      </div>
      <input type=text [(ngModel)]=filter style="flex-grow: 1; min-width: 0;" (focusin)=show()>
      <fa-icon icon="times" *ngIf=selected?.length (click)="remove_all()" class="cursor-pointer text-gray-500"></fa-icon>
   </div>
   <div *ngIf=active class="absolute top-full min-w-full max-h-[250px] overflow-y-auto bg-white">
      <div *ngIf="items?.length >= threshold" class="flex flex-row bg-gray-200">
         <div (click)="select_all()" class="py-2 px-3 cursor-pointer hover:bg-gray-300">
            <fa-icon [icon]="['far', 'check-square']"></fa-icon>
            Selecccionar todo
         </div>
         <div (click)="remove_all()" class="py-2 px-3 cursor-pointer hover:bg-gray-300">
            <fa-icon [icon]="['far', 'square']"></fa-icon>
            Deseleccionar todo
         </div>
      </div>
      <div *ngFor="let item of filtered_items" (click)="selected.includes(item) ? remove(item) : add(item)"
         class="py-1 px-3 cursor-pointer hover:bg-blue-100 {{ selected.includes(item)? 'bg-blue-400 text-white hover:bg-blue-500' : '' }}">
         {{ converter? converter(item) : item }}
      </div>
   </div>
</div>
