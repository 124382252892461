<h2 class="mb-6">Asignación de Comercios</h2>

<div class="card w-full">
   <div class="flex flex-row">
      <div class="mr-8">
         <h4>Agencia:</h4>
         <select [(ngModel)]="selected_agency_id" (change)="handle_agency_selection()" class="min-w-[350px] border rounded py-1 px-2">
            <option *ngIf="!selected_agency_id"></option>
            <option *ngFor="let agency of agencies" [value]="agency.id">{{ agency.description }}</option>
         </select>
      </div>
      <div>
         <h4>Usuarios:</h4>
         <select [(ngModel)]="selected_user_id" (change)="handle_user_selection()" class="min-w-[350px] border rounded py-1 px-2">
            <option *ngIf="!selected_user_id"></option>
            <option *ngFor="let user of users" [value]="user.id">{{ user.description }}</option>
         </select>
      </div>
   </div>

   <div>
      <div class="flex flex-row w-full mt-10">
         <div class="flex-1 pr-6 mr-6">
            <h3>Comercios disponibles</h3>
            <div class="rounded-md border border-gray-200 h-[350px] overflow-y-auto">
               <div *ngFor="let pos of filtered_commerces" (click)="user_commerces.push(pos)"
                  class="w-full px-4 py-2 flex flex-row cursor-pointer hover:bg-blue-400 hover:text-white group">
                  <div style="flex-grow: 1;">{{ pos.name }}</div>
                  <fa-icon icon="chevron-right" class="invisible group-hover:visible"></fa-icon>
               </div>
            </div>
         </div>
         <div class="flex-1">
            <h3>Comercios asignados a usuario</h3>
            <div class="rounded-md border border-gray-200 h-[350px] overflow-y-auto">
               <div *ngFor="let pos of user_commerces" (click)="user_commerces.splice(user_commerces.indexOf(pos), 1)"
                  class="w-full px-4 py-2 flex flex-row cursor-pointer hover:bg-red-400 hover:text-white group">
                  <div style="flex-grow: 1;">{{ pos.name }}</div>
                  <fa-icon icon="times" class="invisible group-hover:visible"></fa-icon>
               </div>
            </div>
         </div>
      </div>
      <div class="flex flex-row-reverse mt-4">
         <button (click)="save()" class="btn blue">
            <fa-icon icon="save"></fa-icon>
            {{ !working? 'Modificar' : 'Modificando...' }}
            <busy *ngIf="working"></busy>
         </button>
      </div>
   </div>
</div>