import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommerceConciliationComponent } from './conciliation/commerce-conciliation/commerce-conciliation.component';
import { ConciliationComponent } from './conciliation/conciliation.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
  { path: 'users',            component: UsersComponent                 },
  { path: 'conciliation',    component: ConciliationComponent         },
  { path: 'conciliation/:id', component: CommerceConciliationComponent },
  { path: 'portfolio',        component: PortfolioComponent             },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommerceRoutingModule { }
