import { Component } from '@angular/core';
import { Agency, ClientContract, Commerce, CommerceInfo } from 'src/app/shared/models/models';
import { DataService } from 'src/app/shared/services/data.service';
import { error_toast } from 'src/app/utilities/toaster/toaster.utilities';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: []
})
export class PortfolioComponent {
  agencies: Agency[];
  selected_agency_id: number;
  agency_commerces_cache: { [key: number]: CommerceInfo[] } = { };

  agency_commerces: CommerceInfo[];
  selected_commerce_id: number;
  commerce_portfolio_cache: { [key: number]: ClientContract[] } = { };

  portfolio: ClientContract[];

  filter: string;

  constructor(private api: DataService) {
    api.agencies(response => {
      if (response.succeeded) {
        this.agencies = response.data;
      } else error_toast(response.error.message);
    });
  }

  handle_agency_selection() {
    this.portfolio = [];
    const id = this.selected_agency_id;
    const cached_commerces = this.agency_commerces_cache[id];
    if (cached_commerces) {
      this.agency_commerces = cached_commerces;
    } else {
      this.api.agency_commerces(id, response => {
        if (response.succeeded) {
          this.agency_commerces_cache[id] = response.data;
          this.agency_commerces = response.data;
        } else error_toast(response.error.message);
      });
    }
  }

  handle_commerce_selection() {
    this.portfolio = [];
    const commerce_id = this.selected_commerce_id;
    const agency_id = this.selected_agency_id;
    const cached_portfolio = this.commerce_portfolio_cache[commerce_id];
    if (cached_portfolio)
      this.portfolio = cached_portfolio;
    else {
      this.api.commerce_portfolio(commerce_id, agency_id, response => {
        if (response.succeeded) {
          this.commerce_portfolio_cache[commerce_id] = response.data;
          this.portfolio = response.data;
        } else error_toast(response.error.message);
      });
    }
  }
}
