import { Component } from '@angular/core';
import { Agency, CommerceInfo, User, UserInfo } from 'src/app/shared/models/models';
import { DataService } from 'src/app/shared/services/data.service';
import { error_toast, success_toast } from 'src/app/utilities/toaster/toaster.utilities';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: []
})
export class UsersComponent {
  agencies: Agency[];
  selected_agency_id: number;

  agency_commerces: CommerceInfo[];

  agency_commerces_cache: { [key: number]: CommerceInfo[] } = { };
  
  users: User[];
  selected_user_id: number;

  user_commerces: CommerceInfo[] = [];

  user_commerces_cache: { [key: number]: CommerceInfo[] } = { };
  
  get filtered_commerces(): any[] {
    return this.agency_commerces?.filter(p => this.user_commerces.every(up => up.code !== p.code));
  }

  working = false;

  constructor(private api: DataService) {
    api.agencies(response => {
      if (response.succeeded) {
        this.agencies = response.data;
      } else error_toast(response.error.message);
    });
    api.users(response => {
      if (response.succeeded) {
        this.users = response.data;
      } else error_toast(response.error.message);
    });
  }

  handle_agency_selection() {
    const id = this.selected_agency_id
    const cached_commerces = this.agency_commerces_cache[id];
    if (cached_commerces) {
      this.agency_commerces = cached_commerces;
    } else {
      this.api.agency_commerces(id, response => {
        if (response.succeeded) {
          this.agency_commerces_cache[id] = response.data;
          this.agency_commerces = response.data;
        } else error_toast(response.error.message);
      });
    }
  }

  handle_user_selection() {
    const id = this.selected_user_id;
    const cached_commerces = this.user_commerces_cache[id];
    if (cached_commerces) {
      this.user_commerces = cached_commerces;
    } else {
      this.api.user_commerces(id, response => {
        if (response.succeeded) {
          this.user_commerces_cache[id] = response.data;
          this.user_commerces = response.data;
        } else error_toast(response.error.message);
      });
    }
  }

  save() {
    const id = this.selected_user_id;
    const username = this.users?.find(u => u.id == <any>id)?.description;
    const commerce_ids = this.user_commerces?.map(c => c.id);
    this.working = true;
    this.api.assign_user_commerces(id, commerce_ids, response => {
      this.working = false;
      if (response.succeeded)
        success_toast(`Asociación de comercios actualizada para el usuario '${username}'`);
      else
        error_toast(response.error.message);
    });
  }
}
