import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LotDetailed, Transaction } from 'src/app/shared/models/models';
import { DataService } from 'src/app/shared/services/data.service';
import { State } from 'src/app/shared/state.shared';
import { error_toast } from 'src/app/utilities/toaster/toaster.utilities';
import { distinct, titlecase, sum } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-lot',
  templateUrl: './lot.component.html',
  styleUrls: ['./lot.component.less']
})
export class LotComponent {
  mode: 'postpaid' | 'prepaid';
  sum = sum;
  lot: LotDetailed = {
    id : 0,
   commerce: {id : 0,description :''},
   number_of_payments: 0,
   total: 0,
   total_nullified: 0,
   date_opened:'',
   date_closed:'',
   prepaid: false,
   transactions: [],
  };
  filter_string: string;

  transaction_groups: { payment_method: string, transactions: Transaction[],amount:number }[];

  constructor(private api: DataService, active_route: ActivatedRoute, private router: Router) {
    active_route.params.subscribe(params => {
      if (![params.mode, params.id].includes(undefined)) {
        this.mode = params.mode;
        api.lot_detailed(params.mode, params.id, response => {
          if (response.succeeded) {
            this.lot = response.data;
             this.transaction_groups = distinct(this.lot?.transactions.map(t => t.payment_method), _ => _.id)
              .map(m => {
                var data = this.lot.transactions.filter(t => t.payment_method.id == m.id);
                return {
                  payment_method: m.description,
                  transactions:data,
                  amount: sum(data.map(x => x.amount)),
                }
              });
          } else error_toast(response.error.message);
        });
      } else router.navigate(['']);
    });
  }

  print() {
    this.api.payment_methods(response => {
      if (response.succeeded) {
        State.lot_to_print = this.lot;
        State.payment_methods = response.data
        this.router.navigate(['lot-print']);
      } else error_toast(response.error.message);
    })
  }


  back() { this.router.navigate(['transactions', 'open-lots']) }
}
