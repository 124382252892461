import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataService } from './services/data.service';
import { HttpClientModule } from '@angular/common/http';
import { PhonePipe } from './pipes/phone.pipe';

@NgModule({
  declarations: [PhonePipe],
  imports: [
    CommonModule,
    HttpClientModule,

  ],
  providers: [DataService],
  exports:[PhonePipe]
})
export class SharedModule { }
