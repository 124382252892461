import { LotDetailed, OrderDiscount, PaymentMethod, UserInfo } from "./models/models";

export class State {
   private static _auth_token: string;
   private static logos: Map<string, string> = new Map<string, string>([
      ["EDEESTE", "logo_edeeste.png"],
      ["EDENORTE", "logo_edenorte.png"],
      ["EDESUR", "logo_edesur.png"],
      ["ASDN", "logo_asdn.png"],
      ["CLARO", "logo_claro.png"],
      ["CAASD", "logo_caasd.png"],
      ["ALTICE", "logo_altice.png"],
      ["VIVA", "logo_viva.png"],
      ["INAPA", "logo_inapa.png"],
      ["NATCOM", "logo_natcom.png"],
      ["DIGICEL", "logo_digicel.png"],
      ["PPE", "logo_ppe.png"],
      ["CORAAVEGA", "logo_coraavega.png"],
      ["CORAAPLATA", "logo_coraaplata.png"],
      ["WIND", "logo_windtelecom.png"],
      ["ALV", "logo_alv.png"],
    ]);

   static get auth_token(): string {
      if (!this._auth_token)
         this._auth_token = localStorage.getItem('auth_token');
      return this._auth_token;
   }
   static set auth_token(value: string) {
      localStorage.setItem('auth_token', value);
      this._auth_token = value;
   }

   private static _user: UserInfo;
   static get user(): UserInfo {
      if (!this._user)
         this._user = JSON.parse(localStorage.getItem('user'));
      return this._user;
   }
   static set user(value: UserInfo) {
      localStorage.setItem('user', JSON.stringify(value));
      this._user = value;
   }

   static getUserPermiss(permiso : string):boolean {
    return this.user.permission.filter(x=> x.description === permiso).length > 0 ? true : false;
   }

   static  getLogo(logo:string){
    return this.logos.get(logo);
   }

   logo = '';

   static lot_to_print: LotDetailed;
   static discount_to_print: OrderDiscount;
   static payment_methods: PaymentMethod[];

   static clear() {
      this.auth_token = null;
      this.user = null;
   }
}
