import { formatDate } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { Agency, CommerceInfo, Item, Transaction } from 'src/app/shared/models/models';
import { DataService } from 'src/app/shared/services/data.service';
import { error_toast } from 'src/app/utilities/toaster/toaster.utilities';
import {  export_text_file, export_xlsx_file, format_date_iso, getDates, sum, today } from 'src/app/utilities/utilities';
import { TransactionTableComponent } from '../transaction-table/transaction-table.component';
import { MultiSelectComponent } from 'src/app/utilities/multi-select/multi-select.component';


@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: []
})
export class TransactionsComponent {
  agencies: Agency[] = [];
  selected_agency_id: number;

  commece_cache: { [key: number]: CommerceInfo[] } = { };

  agency_commerces: CommerceInfo[] = [];
  selected_commerces: CommerceInfo[] = [];

  @ViewChild('myMultiSelect') myMultiSelect: MultiSelectComponent<CommerceInfo>;

  pos_name = (pos: CommerceInfo) => pos.name;

  date_start: string | Date;
  date_end:   string | Date;

  today: string | Date = format_date_iso(today());

  working = false;

  get can_query(): boolean {
    return !!(this.selected_commerces?.length && this.date_start && this.date_end);
  }

  transactions: Transaction[];

  @ViewChild('transaction_table') transaction_table: TransactionTableComponent;

  constructor(private api: DataService) {
    this.date_start = this.date_end = this.today;

    api.user_agencies(response => {
      if (response.succeeded) {
        this.agencies = response.data;
        const stored_commerces = JSON.parse(localStorage.getItem('selected_commerces')) as CommerceInfo[] || [];

        stored_commerces.forEach(element => {
          this.myMultiSelect.add(element);
        });

      } else error_toast(response.error.message);
    });

  }

  handle_agency_change() {
    const cached_commerces = this.commece_cache[this.selected_agency_id];
    if (cached_commerces) {
      this.agency_commerces = cached_commerces;
    } else {
      this.api.agency_commerces(this.selected_agency_id, response => {
        if (response.succeeded) {
          this.commece_cache[this.selected_agency_id] = response.data;
          this.agency_commerces = response.data;
        } else error_toast(response.error.message);
      });
    }
  }

  async query() {
    // Almacenar los comercios en el local storage
    localStorage.setItem('selected_commerces', JSON.stringify(this.selected_commerces));

    this.working = true;
    var call = [];
    this.transactions = [];

    for (const element of this.selected_commerces) {
      try {
        const response = await this.api.transactions_by_commerce(element.id, this.date_start, this.date_end);
        this.transactions = this.transactions.concat(response).sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());
      } catch (error) {
        // Manejar errores si ocurren durante la llamada a la API
        console.error('Error:', error);
        // Mostrar un mensaje de error si la llamada no tuvo éxito
        if (error instanceof Error) {
          error_toast(error.message);
        }

        this.transactions = [];
      }
    }

    this.working = false;
      
  }

  export_excel_detalle() {
    const headers = [
      "Comercio",
      "Tipo",
      "Facturador",
      "Metodo",
      "Fecha",
      "ID",
      "Monto",
      "Cliente/Referencia",
      "Estado",
      "Respuesta"
   ];	

    const transactions = this.transaction_table.filtered_transactions.map(t => [
      t.commerce.description,
      t.prepaid ? "PREPAGO" : "POSPAGO",
      t.invoicer.description,
      t.payment_method.description,
      typeof t.date === 'string' ? t.date : formatDate(t.date, 'yyyy-MM-dd HH:mm:ss', 'es-DO'),
      t.id,
      t.amount,
      t.client,
      t.status.id != 3 ? "EXITOSA" : "FALLIDA",
      t.response.code
    ]);

    const filename = "transacciones_" + this.date_start + "-HASTA-" + this.date_end;
    export_xlsx_file(filename, headers, transactions);
  }

  exportar_excel_total() {
    const transaction_f =  this.transaction_table.filtered_transactions.filter(t => t.response.code.endsWith('000') && !t.response.code.includes('C'));
    const data = [];

    for (let i=0; i<this.selected_commerces.length; i++){
      const commerce = this.selected_commerces[i].name;
      const temp_invoicers = transaction_f.filter(t => t.commerce.description == commerce).map(t => t.invoicer).distinct(i => i.id);
      var invoicers: Item[] = [];
      temp_invoicers.forEach(element => {
        invoicers.push(element);
        invoicers.push({id: -1, description: "# transacciones " + element.description });
      });

      data.push([this.selected_commerces[i].name]);
      data.push(["Pospago", ...invoicers.map(i => ""), "", "", "Prepago"]);
      data.push(['Dia', ...invoicers.map(i => i.description), 'Total', "", 'Dia', ...temp_invoicers.map(i => i.description), 'Total']);

      getDates(this.date_start, this.date_end).forEach(date => {
        const pospaid_transactions = this.generate_fila(temp_invoicers, transaction_f, date, false, commerce);
        const prepaid_transactions = this.generate_fila(temp_invoicers, transaction_f, date, true, commerce);

        data.push([date, ...pospaid_transactions, "", date, ...prepaid_transactions]);
      });

      var invoicers_totals = ["Suma"]
      temp_invoicers.forEach(i => {
        const amount_total = sum(transaction_f.filter(t => t.commerce.description == commerce && t.invoicer.id == i.id && !t.prepaid).map(t => t.amount));
        const nb_transactions_total = transaction_f.filter(t => t.commerce.description == commerce && t.invoicer.id == i.id && !t.prepaid).length;
        invoicers_totals.push(amount_total.toString());
        invoicers_totals.push(nb_transactions_total.toString());
      });
      invoicers_totals.push(sum(transaction_f.filter(t => t.commerce.description == commerce && !t.prepaid).map(t => t.amount)).toString());

      data.push(invoicers_totals);
      
      data.push([""]);
    }

    const filename = "total_" + this.date_start + "-HASTA-" + this.date_end;
    export_xlsx_file(filename, [], data);
 }

generate_fila(invoicer: Item[], transactions: Transaction[], date: String, is_prepaid: Boolean, commerce: string): number[] {
  const res: number[] = [];

  const filtered_transaccion = transactions.filter(t => t.prepaid == is_prepaid && t.commerce.description == commerce && (typeof t.date == "string" ? t.date.split("T")[0] == date : formatDate(t.date, 'yyyy-MM-dd', 'es-DO') == date));
  let total = 0;

  invoicer.forEach(i => {
    const t= sum(filtered_transaccion.filter(t => t.invoicer.id == i.id).map(t => t.amount))
    res.push(t);
    if (!is_prepaid)
      res.push(filtered_transaccion.filter(t => t.invoicer.id == i.id).length);
    total += t;
  });

  res.push(total);

  return res;
}
}
