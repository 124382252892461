import { ValidationManager, validation, validator } from './../utilities/validator.utilities';
import { Component } from '@angular/core';
import { DataService } from '../shared/services/data.service';
import { error_toast, success_toast } from '../utilities/toaster/toaster.utilities';
import { Router } from '@angular/router';
import { State } from '../shared/state.shared';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent {
  data = {
    username: <string>null,
    password: <string>null
  };

  show_password = false;
  fields: ValidationManager;

  constructor(private api: DataService, private router: Router) { }
  ngOnInit() {
    this.fields = validator({
      username: [validation.required],
      password: [validation.required]
    })
  }

  login() {
    if (this.fields.all_valid()) {
      this.api.login(this.data.username, this.data.password, response => {
        if (response.succeeded) {
          State.user = response.data.user;
          State.auth_token = response.data.token;
          success_toast('¡Bienvenido!');
          this.router.navigate(['']);
        } else error_toast(response.error.message);
      });
    }
  }
}