import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommerceRoutingModule } from './commerce-routing.module';
import { UsersComponent } from './users/users.component';
import { ConciliationComponent } from './conciliation/conciliation.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { UtilitiesModule } from '../utilities/utilities.module';
import { FormsModule } from '@angular/forms';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { CommerceConciliationComponent } from './conciliation/commerce-conciliation/commerce-conciliation.component';

@NgModule({
  declarations: [
    UsersComponent,
    ConciliationComponent,
    PortfolioComponent,
    CommerceConciliationComponent,
  ],
  imports: [
    CommonModule,
    CommerceRoutingModule,
    UtilitiesModule,
    FormsModule,
    FontAwesomeModule
  ]
})
export class CommerceModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab);
  }
}
