import { AfterViewChecked, Component } from '@angular/core';
import { Router } from '@angular/router';
import { LotDetailed, PaymentMethod, Transaction } from 'src/app/shared/models/models';
import { State } from 'src/app/shared/state.shared';
import { sum, titlecase } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-lot-print',
  templateUrl: './lot-print.component.html',
  styleUrls: []
})
export class LotPrintComponent {
  lot: LotDetailed = State.lot_to_print;
  payment_methods: PaymentMethod[] = State.payment_methods;

  titlecase = titlecase;

  constructor(private router: Router) {
    if (!this.lot || !this.payment_methods)
      router.navigate([''])
  }
  ngAfterViewInit(): void {
    window.print();
    window.history.back();
  }

  total(method_id: number): number {
    return sum(this.lot.transactions.filter(t => t.payment_method.id == method_id && [2, 7].includes(t.type.id)).map(t => t.amount));
  }

  transactions(method_id: number): Transaction[] {
    return this.lot.transactions.filter(t => [2, 7].includes(t.type.id)).filter(t => t.payment_method.id == method_id);
  }

  get nullifications(): Transaction[] {
    return this.lot.transactions.filter(t => t.type.id == 3);
  }
}
