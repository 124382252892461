import { State } from './../shared/state.shared'
import { Component } from '@angular/core'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { DataService } from '../shared/services/data.service'

@Component({
	selector: 'app-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.less'],
})
export class ContainerComponent {
	user = State.user
	menu: MenuItem[] = []

	selected_item: MenuItem

	app_state = State

	selected(item: MenuItem): boolean {
		return this.selected_item === item || item.children?.some((i) => this.selected_item === i || i.children?.includes(this.selected_item))
	}

	current_url: string
	active(item: MenuItem): boolean {
		return item.link === this.current_url || item.children?.some((i) => i.link === this.current_url)
	}

	constructor(private router: Router, api: DataService) {
		if (State.user !== null) {
			this.menu = this.generate_menu
			this.menu = this.generate_menu
		} else this.router.navigateByUrl('/login')

		router.events.subscribe((event) => {
			if (event instanceof NavigationStart || event instanceof NavigationEnd) this.current_url = event.url
		})
	}

	go_or_select(item: MenuItem) {
		if (item.link) this.router.navigateByUrl(item.link)
		else if (item.children?.length) {
			if (this.selected_item === item) {
				this.selected_item = this.menu.includes(item) ? null : this.menu.find((i) => i.children?.includes(item))
			} else {
				this.selected_item = item
			}
		}
	}

	logout() {
		State.clear()
		this.router.navigate(['login'])
	}

	get generate_menu() {
		var menu: MenuItem[] = []
		menu = [{ label: 'Inicio', icon: ['far', 'chart-bar'], link: 'home' }]
		var options = this.user.permission.filter((x) => x.id == 1 || x.id == 2 || x.id == 4 || x.id == 5 || x.id == 6)
		if (options.length > 0) {
			menu.push({
				label: 'Transacciones',
				icon: ['far', 'building'],
				children: options.map((x) => {
					return this.DitionaryOptions[x.id]
				}),
			})
		}
		options = this.user.permission.filter((x) => x.id == 8 || x.id == 9 || x.id == 10)
		if (options.length > 0) {
			menu.push({
				label: 'Comercios',
				icon: ['far', 'list-alt'],
				children: options.map((x) => {
					return this.DitionaryOptions[x.id]
				}),
			})
		}

		menu.push({
			label: 'Ordenes de pago',
			icon: ['far', 'file-alt'],
			children: [
				{ label: 'Ordenes', link: '/orders' },
				{ label: 'Descuentos', link: '/orders/discounts' },
			],
		})

		options = this.user.permission.filter((x) => x.id == 13 || x.id == 15 || x.id == 17 || x.id == 19 || x.id == 21)
		if (options.length > 0) {
			menu.push({
				label: 'Adm. del Sistema',
				icon: ['far', 'sun'],
				children: options.map((x) => {
					return this.DitionaryOptions[x.id]
				}),
			})
		}

		return menu
	}

	DitionaryOptions: { [id: number]: MenuItem } = {
		1: { label: 'Transacciones por Contrato', link: '/transactions/contracts' },
		2: { label: 'Puntos de Venta', link: '/transactions/points-of-sale' },
		5: { label: 'Lotes Abiertos', link: '/transactions/open-lots' },
		6: { label: 'Lotes Cerrados', link: '/transactions/closed-lots' },
		4: { label: 'Transacciones', link: '/transactions/transactions' },
		8: { label: 'Asignación Comercios', link: '/commerces/users' },
		9: { label: 'Gestión de Comercios', link: '/commerces/conciliation' },
		10: { label: 'Cartera de Cobro', link: '/commerces/portfolio' },
		13: { label: 'Agencias', link: '/admin/agencies' },
		15: { label: 'Facturadores', link: '/admin/invoicers' },
		17: { label: 'Comercios', link: '/admin/commerces' },
		19: { label: 'Usuarios del Sistema', link: '/admin/users' },
		21: {
			label: 'Configuración de Terminal',
			link: '/admin/terminal-configuration',
		},
	}
}

interface MenuItem {
	label: string
	icon?: IconProp
	link?: string
	children?: MenuItem[]
}
